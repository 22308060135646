<template>
    <div class="mx-auto"></div>
</template>

<script>


export default ({
    name: 'PageNotFoundView',

    created(){
        this.$emit('loaded')
    },
})
</script>