<template class="mx-auto ">
  <LoadingVue v-if="!loaded" />
  <div v-else id="Container" ref="container" class="flex justify-around fixed w-full h-full">

    <!-- map -->
    <div id="Map" class="flex flex-col items-center w-fit">

      <!-- toolbar  -->
      <div id="ToolBar" class="flex justify-center relative top-[15px] w-full z-30">
        <div class="flex flex-row py-1 items-center justify-center z-40">
          <ButtonVue ref="create" class="mx-1 drop-shadow-md" :style="{ opacity: '100%' }" @click="toggleTool('create')"
            :filling="'filled'" :label="null" :height="'large'" :icon="'PlusCircleIcon'" :state="'default'" />
          <ButtonVue ref="select" class="mx-1 drop-shadow-md" :style="{ opacity: '50%' }" @click="toggleTool('select')"
            :filling="'filled'" :label="null" :icon="'CursorArrowRippleIcon'" :height="'large'" :state="'default'" />
          <ButtonVue ref="move" class="mx-1 drop-shadow-md" :style="{ opacity: '50%' }" @click="toggleTool('move')"
            :filling="'filled'" :label="null" :icon="'ArrowsRightLeftIcon'" :height="'large'" :state="'default'" />
          <ButtonVue ref="section" class="mx-1 drop-shadow-md" :style="{ opacity: '50%' }"
            @click="toggleTool('section')" :filling="'filled'" :label="null" :icon="'SquaresPlusIcon'" :height="'large'"
            :state="'default'" />
          <ButtonVue ref="settings" class="mx-1 drop-shadow-md" :style="{ opacity: '50%' }"
            @click="toggleTool('settings')" :filling="'filled'" :label="null" :icon="'Cog6ToothIcon'" :height="'large'"
            :state="'default'" />
          <ButtonVue ref="delete" class="mx-1 drop-shadow-md" :style="{ opacity: '50%' }" @click="toggleTool('delete')"
            :filling="'filled'" :label="null" :icon="'TrashIcon'" :height="'large'" :state="'danger'" />
          <ButtonVue v-if="undoAvailable" ref="undo" class="mx-1 drop-shadow-md" :style="{ opacity: '50%' }"
            @click="undoAction()" :filling="'filled'" :label="null" :icon="'BackwardIcon'" :height="'large'"
            :state="'danger'" />
          <ButtonVue ref="size" class="mx-1 drop-shadow-md" :style="{ opacity: '50%' }" @click="toggleTool('size')"
            :filling="'filled'" :label="null" :icon="'ArrowsPointingOutIcon'" :height="'large'" :state="'default'" />
        </div>
      </div>

      <!-- grid -->
      <div id="Grid" ref='grid' class="px-2 justify-center rounded-3xl relative top-[25px] cursor-cell "
        :style="{ width: 'fit-content' }">
        <div class="grid grid-cols-40 border-t border-r border-gray-300 w-fit" ref="grid">
          <div v-for="(row, rowIndex) in lengthStore" :key="`${rowIndex}`" class="flex">
            <div v-for="(column, columnIndex) in widthStore" class="border-l border-b border-gray-300"
              :id="`${rowIndex}_${columnIndex}`" :key="`${rowIndex}_${columnIndex}`" :ref="`${rowIndex}_${columnIndex}`"
              :style="{ height: squareSize + 'px', width: squareSize + 'px', backgroundColor: '', border: '' }">
              <div class="w-full h-full z-40 relative" @click="clickedSquare(columnIndex, rowIndex)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- legend -->
    <div id="Legend" class="flex flex-col relative top-[15px] items-center ">
      <div class="py-2 z-50">
        <ButtonVue :label="'Sauvegarder'" :height="'large'" :state="'default'" @click="saveGrid()" class="w-[150px]" />
      </div>
      <div class="flex flex-col flex-shrink border-[1px] border-gray-500 mx-2 rounded-3xl z-40 bg-gray-50"
        :style="{ 'max-height': `${lengthStore * squareSize}px` }">
        <div class="border-b-[1px] border-gray-500 w-full flex justify-center">
          <ButtonVue class="mx-1 !bg-transparent !shadow-none !text-gray-900  " :filling="'text'" :label="'Legende'"
            :icon="null" :height="'large'" :state="'neutral'" />
        </div>
        <div class="pt-2 max-h-100% overflow-y-auto h-100%">
          <div v-for="categorie in categories" :key="categorie.id" class="flex flex-row items-center my-2"
            :class="{ 'ml-8': screenWidth >= 1400, 'ml-4': screenWidth < 1400 }">
            <div class="min-w-[20px] min-h-[20px] border border-gray-700 drop-shadow-md mr-2 cursor-pointer"
              :style="{ backgroundColor: categorie.fill }"
              @click="toggleCategorie({ fill: categorie.fill, section: categorie.section, id: categorie.id })">
            </div>
            <ButtonVue
              class="mx-1 !bg-transparent !shadow-none !text-left !text-gray-900 !font-normal cursor-auto hover:opacity-100 hover:shadow-none"
              :filling="'text'" :label="categorie.section" :icon="null" :height="'large'" :state="'neutral'" />
          </div>
        </div>
      </div>
    </div>


    <!-- Square representing the pointer -->
    <div v-if="showPointerSquare" ref='pointer' class="pointer-square fixed z-10"
      :style="{ top: pointerPosition.y + 'px', left: pointerPosition.x + 'px', height: squareSize + 'px', width: squareSize + 'px', backgroundColor: activeCategorie.fill }">
    </div>

    <div v-if="showPointerSelection" class="pointer-div fixed z-10"
      :style="{ top: pointerPosition.y + 'px', left: pointerPosition.x + 'px', width: pointerStyle.width, height: pointerStyle.height }">
      <!-- Render the individual squares -->
      <div v-for="(row, rowIndex) in pointerRows" :key="`${rowIndex}`" class="flex">
        <div v-for="(square, columnIndex) in row" :key="`${rowIndex}_${columnIndex}`" class="pointer-square"
          :style="{ height: squareSize + 'px', width: squareSize + 'px', backgroundColor: square.fill }">
        </div>
      </div>
    </div>


    <!-- modal for section creation -->
    <Modal v-show="modalSectionOpen" :title="''" :confirmButtonLabel="'AJOUTER'" @closeModal="modalSectionOpen = false"
      @confirm="sectionSquare()" class="z-50 w-fit">
      <template v-slot:body>
        <div clas="flex flex-col">
          <div class="flex flex-row w-full justify-center">
            <InputVue class=" min-w-[100px] mb-5 !mr-[50px]" v-model="section_y" :height="'large'"
              :label="'Largeur (x)'" :placeholder="''" :state="'default'" :message="''" />
            <InputVue class=" min-w-[100px] mb-5" v-model="section_x" :height="'large'" :label="'Longueur (y)'"
              :placeholder="''" :state="'default'" :message="''" />
          </div>
          <div class="flex flex-row w-full justify-around">
            <SelectVue class="min-w-[300px] mb-2 mx-1" v-model="section" :options="sections" :label="'Catégorie'"
              :height="'large'" :state="'default'" @update:modelValue="section = $event" />
          </div>
        </div>
      </template>
    </Modal>

    <!-- modal for parameters -->
    <Modal v-show="modalSettingsOpen" :title="''" :confirmButtonLabel="'AJOUTER'"
      @closeModal="modalSettingsOpen = false" @confirm="settingsSquare()" class="z-50 w-fit">

      <template v-slot:body>
        <div class="flex flex-row w-full justify-around">
          <SelectVue class="min-w-[150px] mb-2 mx-1" v-model="aisle" :options="aisles" :label="'Allée'"
            :height="'large'" @update:modelValue="aisle = $event" />
          <SelectVue class="min-w-[150px] mb-2 mx-1" v-model="position" :options="positions" :label="'Position'"
            :height="'large'" @update:modelValue="position = $event" />
          <SelectVue class="min-w-[300px] mb-2 mx-1" v-model="section" :options="sections" :label="'Catégorie'"
            :height="'large'" :state="'default'" @update:modelValue="section = $event" />
        </div>
      </template>
    </Modal>

    <!-- modal for resizing grid -->
    <Modal v-show="modalSizeOpen" :title="''" :confirmButtonLabel="'MODIFIER'" @closeModal="modalSizeOpen = false"
      @confirm="resizeGrid()" class="z-50 w-fit">

      <template v-slot:body>
        <div class="flex flex-row w-full justify-around">
          <!-- plutot mettre des fleches et des +/- pour que ce soit plus easy a comprendre -->
          <InputVue class=" min-w-[100px] mb-5 !mr-[50px]" v-model="new_w" :height="'large'"
            :label="'Largeur magasin (x)'" :placeholder="''" :state="'default'" :message="''" />
          <InputVue class=" min-w-[100px] mb-5 !mr-[50px]" v-model="new_l" :height="'large'"
            :label="'Longueur magasin (y)'" :placeholder="''" :state="'default'" :message="''" />
        </div>
      </template>
    </Modal>

  </div>
</template>


<script>

import ButtonVue from '@/components/elements/Button.vue';
import API from '@/utils/MapUtils/API.js'
import LoadingVue from "@/components/elements/Loading.vue"
import InputVue from '@/components/elements/Input.vue'
import Modal from "@/components/containers/Modal.vue"
import SelectVue from '../components/elements/Select.vue'

export default {
  components: {
    ButtonVue,
    LoadingVue,
    SelectVue,
    InputVue,
    Modal
  },
  data() {
    return {
      screenHeight: window.innerHeight, // hauteur de la fenetre
      screenWidth: window.innerWidth, // largeur de la fenetre

      API: null,

      widthStore: 0, // valeur de la largeur X du magasin (plus grande valeur)
      lengthStore: 0, // valeur de la longueur Y du magasin (plus petite valeur)
      squareSize: 25, // valeur initiale d'un carre (celle-ci est recalculee selon la taille de l'ecran)

      grid: [], // represente la carte/ le magasin grid[x][y] = un lineaire
      squares: [], // liste des carres/ lineaires places sur la carte
      categories: [], // liste des categories de produit = la legende

      showPointerSquare: true, // etat du pointeur "carré"
      showPointerSelection: false, // etat du pointeur "selection"
      pointerPosition: { x: 0, y: 0 }, // position du pointeur actif

      activeTool: 'create', // bouton de la barre d'outil qui est active, par defaut 'create'
      activeCategorie: { fill: '#D3D3D3', section: null }, // categorie selectionnee

      selectedSquares: [], // liste des carres selectiones a deplacer

      alertSquareFilled: true, // par defaut, alerter a chaque carre deja coloré

      undoGrid: null, // version précédente de la carte
      undoAvailable: false, // etat de la possibilité de revenir en arrière sur l'etat de la carte

      modalSectionOpen: false, // statut du modal Section
      modalSettingsOpen: false, // statut du modal Settings
      modalSizeOpen: false, //statut du modal settings

      // coordonnées du carré cliqué
      x: 0,
      y: 0,

      // variables pour modal section
      section_x: 0, // largeur section
      section_y: 0, // longueur section

      // variables pour modal settings
      aisles: [], // liste des aisles
      positions: [], // liste des positions dans les aisles
      sections: [], // liste des categories
      aisle: null, // aisle selectionnee
      position: null, // position selectionnee
      section: null, // categorie selectionnee

      // variables pour modal size
      new_w: 0,
      new_l: 0,

      loaded: false,
    };
  },
  computed: {
    isMobile() {
      return this.$device.isMobile;
    },
    isPortrait() {
      return this.$device.isPortrait;
    },
    // calculer la position de la representation de la selection (pointeur) par rapport a la souris (en bas a droite de la souris)
    pointerStyle() {
      const minX = Math.min(...this.selectedSquares.map(square => square.y));
      const maxX = Math.max(...this.selectedSquares.map(square => square.y));
      const minY = Math.min(...this.selectedSquares.map(square => square.x));
      const maxY = Math.max(...this.selectedSquares.map(square => square.x));
      const width = (maxY - minY + 1) * this.squareSize;
      const height = (maxX - minX + 1) * this.squareSize;

      return {
        width: width + 'px',
        height: height + 'px'
      };
    },
    // generer la representation de la selection (pointeur)
    pointerRows() {
      const minX = Math.min(...this.selectedSquares.map(square => square.x));
      const maxX = Math.max(...this.selectedSquares.map(square => square.x));
      const minY = Math.min(...this.selectedSquares.map(square => square.y));
      const maxY = Math.max(...this.selectedSquares.map(square => square.y));

      const rows = [];
      for (let x = minX; x <= maxX; x++) {
        const row = [];
        for (let y = minY; y <= maxY; y++) {
          const square = this.selectedSquares.find(sq => sq.y === x && sq.x === y);
          if (square) {
            row.push(square);
          } else {
            row.push({ fill: 'transparent' });
          }
        }
        rows.push(row);
      }
      return rows;
    }
  },
  async created() {
    // this.saveGrid();
    // initialiser la classe API
    this.API = new API();

    try {
      // recuperer la carte dans la base de données
      const load = await this.API.getMap();
      console.log(load)
      // recuperer la taille de la fenetre
      this.widthStore = load.size.widthStore;
      this.lengthStore = load.size.lengthStore;

      // generer la grille vide
      this.grid = Array.from({ length: this.widthStore }, () => Array.from({ length: this.lengthStore }, () => null));

      load.squares.forEach(square => {
        this.grid[square.y][square.x] = square;
      });

      this.loaded = true;

    } catch (error) {
      console.error('Error occurred while loading data:', error);
      this.grid = [];
    }

    try {
      // recuperer les categories dans la base de données
      const load = await this.API.getCategories();

      this.categories = load;
      this.sections = this.categories.map((cat) => ({ name: cat.section, value: cat.id }));

    } catch (error) {
      console.error('Error occurred while loading data:', error);
      this.grid = [];
    }

    this.activeCategorie = this.categories[0];

    // calcul de la taille des carreaux
    //                                  - (navbar + toolbar)       - (border)
    var firstSize = ((this.screenHeight - 200) / this.lengthStore) - 1;

    var secondSize = 0;
    if (this.screenWidth < 1400) {
      secondSize = ((this.screenWidth - 200) / this.widthStore) - 1;
    } else {
      secondSize = ((this.screenWidth - 300) / this.widthStore) - 1;
    }

    // selectionner la taille la plus petite pour etre sure que la carte d'affiche sans overflow
    this.squareSize = Math.min(firstSize, secondSize);
  },
  updated() {
    this.fillMap();
  },
  async mounted() {
    // recuperer les positions de linéaires pour le modal settings
    this.Listboard = await this.API.getboard();
    for (let i = 0; i < this.Listboard.length; i++) {
      if (this.Listboard[i].aisle != null) {
        this.aisles.push({ name: this.Listboard[i].aisle, value: this.Listboard[i].aisle })
        this.positions.push({ name: this.Listboard[i].position, value: this.Listboard[i].position })
      }
    }
    // console.log("lineaires", this.Listboard);

    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mousedown', this.handleMouseDown);
  },
  methods: {
    /**
    * @description: recolore le background des carrés placés lors des rerender
    */
    fillMap() {
      let squaresBackup = this.grid.flatMap((innerArray, rowIndex) =>
        innerArray
          .map((value, columnIndex) => value !== null ? { key: `${rowIndex}_${columnIndex}`, fill: value.fill } : null)
          .filter(cell => cell !== null)
      );

      squaresBackup.forEach(square => {
        this.$refs[`${square.key}`][0].style.backgroundColor = square.fill;
      });

      this.selectedSquares.forEach(square => {
        let { x, y } = square;
        let ref = `${y}_${x}`;
        let element = this.$refs[ref];
        if (element) {
          element[0].style.border = '2px dashed black';
        }
      });

    },

    /**
    * @description: annuler la dernière action et revenir à la grille de backup
    */
    undoAction() {
      this.undoAvailable = false;
      this.grid = JSON.parse(JSON.stringify(this.undoGrid));
    },

    /**
    * @description: enregistrer la grille de backup
    */
    saveUndoGrid() {
      this.undoGrid = JSON.parse(JSON.stringify(this.grid));
      this.undoAvailable = true;
    },

    /**
    * @description: déplacer les pointeurs avec la souris
    * @param {*} event
    */
    handleMouseMove(event) {
      if (this.showPointerSquare) {
        this.pointerPosition = {
          x: event.clientX - (this.squareSize / 2),
          y: event.clientY - (this.squareSize / 2)
        };
      }
      if (this.showPointerSelection) {
        this.pointerPosition = {
          x: event.clientX - (this.squareSize / 2),
          y: event.clientY - (this.squareSize / 2)
        };
      }
    },

    /**
    * @description: 
    * @param {*} event
    */
    handleMouseDown(event) {
      const clickedElementId = event.target.parentNode.id;
      const idParts = clickedElementId.split('_');
      const parsedX = parseInt(idParts[0]);
      const parsedY = parseInt(idParts[1]);

      if (Number.isInteger(parsedX) && Number.isInteger(parsedY)) {
        this.y = parsedX;
        this.x = parsedY;
      }

      if (this.activeTool == 'move') {
        this.moveSelection(event);
      }
    },

    /**
    * @description: changer la valeur de activeTool + changer le bouton qui est opaque + cacher/afficher le pointeur
    * @param {*} toolName: l'outil selectionne
    */
    toggleTool(toolName) {
      if (toolName != this.activeTool) {
        // diminuer l'opacite de l'ancien bouton actif
        this.$refs[this.activeTool].$el.style.opacity = '50%'
        this.$refs["delete"].$el.style.opacity = '50%';

        // augmenter l'opacite du bouton actif
        this.$refs[toolName].$el.style.opacity = '100%';
        if (toolName == "select") {
          this.$refs["delete"].$el.style.opacity = '100%';
        }

        if (this.activeTool == "select" && toolName == "delete" && this.selectedSquares.length > 0) {
          if (confirm("Êtes-vous sûr de vouloir supprimer la sélection?")) {
            this.selectedSquares.forEach(selectedSquare => {
              this.deleteSquare(selectedSquare.y, selectedSquare.x);
            });
            this.selectedSquares = [];
            this.$refs['select'].$el.style.opacity = '100%';
          }
        } else {
          // modifier la valeur de activeTool et changer l'opacite du bouton actif
          this.activeTool = toolName;
        }

        // afficher/ cacher le pointeur selon l'outil selectione
        this.showPointerSquare = toolName == 'create';
        this.showPointerSelection = false;

        if (toolName == "size") {
          this.new_l = this.lengthStore;
          this.new_w = this.widthStore;
        }

        // reinitialiser le tableau de carres selectionnes
        if (toolName != "move" && toolName != "select") {
          this.selectedSquares = [];
        }

        if (toolName == "size") {
          this.modalSizeOpen = true;
        }
      }

    },

    /**
    * @description: change la valeur de activeCategorie + changer la couleur du pointeur
    * @param {*} categorie: categorie selectionee
    */
    toggleCategorie(categorie) {
      // console.log(categorie);
      this.activeCategorie = categorie
      if (this.activeTool == 'create') {
        this.$refs.pointer.style.backgroundColor = categorie.fill
      }
    },

    /**
    * @description: appeler la fonction adaptee selon l'outil actif
    * @param {*} x: coordonnee x (colonne) du carre clique
    * @param {*} y: coordonnee y (ligne) du carre clique
    */
    clickedSquare(x, y) {
      // console.log(this.grid)
      switch (this.activeTool) {
        case 'create':
          this.createSquare(x, y);
          break;
        case 'select':
          this.selectSquare(x, y);
          break;
        case 'section':
          this.modalSectionOpen = true;
          break;
        case 'settings':
          this.modalSettingsOpen = true;
          if (this.grid[y][x] != null) {
            this.aisle = this.grid[y][x].aisle;
            this.position = this.grid[y][x].row;
            this.section = typeof this.grid[y][x].section_id === "undefined" ? null : this.sections[this.grid[y][x].section_id].value - 1;
          }
          break;
        case 'delete':
          this.deleteSquare(x, y);
          break;
        default:
          break;
      }
    },

    /**
    * @description: creer un carre : enregistrer les donnees dans le tableau + changer le bg
    * @param {*} x: coordonnee x (colonne) du carre cree
    * @param {*} y: coordonnee y (ligne) du carre cree
    */
    createSquare(x, y) {
      this.saveUndoGrid();

      // changer la couleur du carre dans la grid
      let ref = `${y}_${x}`;
      let element = this.$refs[ref];
      // gestion d'erreur: element existe?
      if (element && element.length > 0) {
        // verifier si la couleur de fond est deja definie
        if (element[0].style.backgroundColor !== '') {
          // demander confirmation a l'utilisateur
          if (confirm("Êtes-vous sûr de vouloir changer la catégorie de ce linéaire?")) {
            // si l'utilisateur confirme, changer la couleur
            element[0].style.backgroundColor = this.activeCategorie.fill;
            // ajouter les informations du nouveau carre dans le tableau grid
            this.grid[y][x] = { fill: this.activeCategorie.fill, section: this.activeCategorie.section, section_id: this.activeCategorie.id, x: x, y: y, aisle: null, row: null };
          }
        } else {
          // si la couleur de fond n'est pas deja definie, changer la couleur sans confirmation
          element[0].style.backgroundColor = this.activeCategorie.fill;
          // ajouter les informations du nouveau carre dans le tableau grid
          this.grid[y][x] = { fill: this.activeCategorie.fill, section: this.activeCategorie.section, section_id: this.activeCategorie.id, x: x, y: y, aisle: null, row: null };
        }
      } else {
        console.error(`Element with ID ${x},${y} not found.`);
      }
    },

    /**
    * @description: selectionner des carrés à déplacer
    * @param {*} x: coordonnee x (colonne) du carre cree
    * @param {*} y: coordonnee y (ligne) du carre cree
    */
    selectSquare(x, y) {
      // verifier si ce carré a déja été selectionné
      const index = this.selectedSquares.findIndex(square => square.x === x && square.y === y);
      let ref = `${y}_${x}`;
      let element = this.$refs[ref];

      // verifier que le carre selectionné est déja un linéaire et non une case vide
      if (this.grid[y][x] != null) {
        if (index === -1) {
          // si non, l'ajouter a la liste de selectionnés
          this.selectedSquares.push({ ref: `${y}_${x}`, x: x, y: y, fill: this.grid[y][x].fill });

          element[0].style.border = '2px dashed black';
        } else {
          // si oui, le supprimer de la liste
          this.selectedSquares.splice(index, 1);
          element[0].style.border = '';
        }
      } else {
        console.error('Cet emplacement ne contient pas encore de linéaire. Il ne peut pas être selectionné.');
      }

      console.log("select result:", this.selectedSquares)
    },

    /**
    * @description: deplacer la selection vers ses nouvelles coordonnées
    * @param {*} event
    */
    moveSelection(event) {
      this.showPointerSelection = !this.showPointerSelection;
      if (this.showPointerSelection) {
        this.pointerPosition = {
          x: event.clientX - (this.squareSize / 2),
          y: event.clientY - (this.squareSize / 2)
        };
      }

      if (!this.showPointerSelection) {
        const minY = Math.min(...this.selectedSquares.map(square => square.y));
        const squaresWithMinY = this.selectedSquares.filter(square => square.y === minY);

        const minXInMinY = Math.min(...squaresWithMinY.map(square => square.x));
        const squareWithMinXInMinY = squaresWithMinY.find(square => square.x === minXInMinY);

        const diffY = this.y - squareWithMinXInMinY.y;
        const diffX = this.x - squareWithMinXInMinY.x;

        let errorMessage = '';
        let errorType = 0;
        
        this.selectedSquares.forEach(square => {
          let newY = square.y + diffY;
          let newX = square.x + diffX;

          if (newY < 0 || newY >= this.lengthStore || newX < 0 || newX >= this.widthStore) {
            errorMessage = 'One or more squares would move outside the grid bounds.';
            errorType = 1;
            return;
          }

          if (this.grid[newY][newX] != null) {
            errorMessage = 'One or more squares would overlap with existing squares.';
            errorType = 2;
            return;
          }
        });

        if (errorType == 1) {
          alert(errorMessage);
          this.showPointerSelection = false;
          return;
        } else if (errorType == 2) {
          let confirmation = confirm(errorMessage);
          if (!confirmation) {
            this.showPointerSelection = false;
            return;
          }
          errorType = 0;
        }

        if (errorType == 0) {
          this.selectedSquares.forEach(square => {
            this.grid[square.y][square.x] = null;

            square.y += diffY;
            square.x += diffX;

            this.grid[square.y][square.x] = {
              fill: square.fill,
              section: square.section,
              section_id: square.section_id,
              x: square.x,
              y: square.y,
              aisle: null,
              row: null
            };
          });
        }
      }
    },


    /**
    * @description: créer une section grâce au modal
    */
    sectionSquare() {
      this.modalSectionOpen = false;
      this.saveUndoGrid();

      if (this.section != null) {
        const category = this.categories.find((cat) => cat.id === this.section);
        this.activeCategorie = category;
      }
      let start_x = this.x;
      let end_x = this.x + parseInt(this.section_x) - 1;
      let start_y = this.y;
      let end_y = this.y + parseInt(this.section_y) - 1;

      // verifier l'overlap
      let squareUsed = 0;
      for (let i = start_x; i <= end_x; i++) {
        squareUsed += this.grid[start_y][i] == null ? 0 : 1;
      }
      for (let i = start_y; i <= end_y; i++) {
        squareUsed += this.grid[i][start_x] == null ? 0 : 1;
      }

      if (squareUsed > 0) {
        const confirmation = confirm('One or more squares would overlap with existing squares. Do you want to proceed?');
        if (!confirmation) {
          return;
        }
      }

      console.log(end_x, end_y);

      // verifier si on sort de la grille
      if (end_y >= this.widthStore || end_x >= this.lengthStore) {
        alert("One or more squares would be outside of the grid")
        return;
      }

      for (let i = start_x; i <= end_x; i++) {
        this.grid[start_y][i] = { fill: this.activeCategorie.fill, section: this.activeCategorie.section, section_id: this.activeCategorie.id, x: i, y: start_y, aisle: null, row: null };
      }
      for (let i = start_y; i <= end_y; i++) {
        this.grid[i][start_x] = { fill: this.activeCategorie.fill, section: this.activeCategorie.section, section_id: this.activeCategorie.id, x: start_x, y: i, aisle: null, row: null };
      }

      this.y = 0;
      this.x = 0;
      this.section_x = 0;
      this.section_y = 0;
    },

    /**
    * @description: associer un carré à une position grâce au modal
    */
    settingsSquare() {
      if (this.section != null) {
        this.modalSettingsOpen = false;
        const category = this.categories.find((cat) => cat.id === this.section);
        this.activeCategorie = category;
        this.grid[this.y][this.x] = { fill: this.activeCategorie.fill, section: this.activeCategorie.section, section_id: this.activeCategorie.id, x: this.x, y: this.y, aisle: parseInt(this.aisle), row: parseInt(this.position) };

        this.y = 0;
        this.x = 0;
        this.section = null;
        this.aisle = null;
        this.position = null;
      } else {
        alert('Veuillez remplir toutes les informations.')
      }
    },

    /**
    * @description: supprimer un carre : enregistrer les donnees dans le tableau + changer le bg
    * @param {*} x: coordonnee x (colonne) du carre cree
    * @param {*} y: coordonnee y (ligne) du carre cree
    */
    deleteSquare(x, y) {
      if (this.grid[y][x] != null) {
        this.saveUndoGrid();

        // ajouter les informations du nouveau carre dans le tableau grid
        this.grid[y][x] = null;

        // changer la couleur du carre dans la grid
        let ref = `${y}_${x}`;
        let element = this.$refs[ref];
        // gestion d'erreur: element existe?
        if (element && element.length > 0) {
          element[0].style.backgroundColor = '';
        } else {
          console.error(`Element with ID ${y}_${x} not found.`);
        }
      }
    },

    /**
    * @description: changer la taille du magasin grâce aux modals
    */
    resizeGrid() {
      this.saveUndoGrid();

      this.new_w = parseInt(this.new_w);
      this.new_l = parseInt(this.new_l);
      if (isNaN(this.new_w) || isNaN(this.new_l)) {
        alert("Veuillez entrer des nombres entiers");
        return;
      }

      this.modalSizeOpen = false;

      this.widthStore = this.new_w;
      this.lengthStore = this.new_l;

      this.grid = Array.from({ length: this.widthStore }, () => Array.from({ length: this.lengthStore }, () => null));

      // calcul de la taille des carreaux
      //                                  - (navbar + toolbar)       - (border)
      var firstSize = ((this.screenHeight - 200) / this.lengthStore) - 1;

      var secondSize = 0;
      if (this.screenWidth < 1400) {
        secondSize = ((this.screenWidth - 200) / this.widthStore) - 1;
      } else {
        secondSize = ((this.screenWidth - 300) / this.widthStore) - 1;
      }

      // selectionner la taille la plus petite pour etre sure que la carte d'affiche sans overflow
      this.squareSize = Math.min(firstSize, secondSize);

      this.undoAction();

    },

    /**
    * @description: sauvegarder la grille sur la base de donnée
    */
    async saveGrid() {
      let cubes = this.grid.flat().filter(cube => cube !== null);
      let size = { widthStore: this.widthStore, lengthStore: this.lengthStore };
      if (cubes !== null && this.categories !== null && size !== null) {
        try {
          const response = await this.API.save(cubes, size); // Wait for the API call to complete
          console.log(response);
          if (response == 200) {
            window.location.replace('/map');
          } else {
            alert('La sauvegarde a échoué. Veuillez réessayer.');
          }
        } catch (error) {
          console.error('Error saving grid:', error);
          alert('La sauvegarde a échoué. Veuillez réessayer.');
        }
      } else {
        alert('La sauvegarde a échoué. Veuillez réessayer.');
      }
    }

  },
  unmounted() {
    // supprimer les event listener
    document.body.style.cursor = 'auto';
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mousedown', this.handleMouseDown);

  }
};
</script>
