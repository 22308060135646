import axios from 'axios'
import Scale from "./Scale"
import WebsocketService from '../WebsocketService'

export default class Board { 


    constructor(object) {
        this.build(object)
    }

    initializeWebSocketListener() {

        WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);

        WebsocketService.sendMessage({
            command: 'subscribe',
            resource: `/api/boards/${this.serialNumber}`,
        });

        WebsocketService.addListener(async (type, data) => {
            if (type === 'message' && data.resource === `/api/boards/${this.serialNumber}` && data.command == "update") {
                await this.retrieveEntity();
            }
        });
    }
    
    unsubscribeWebSocket() {
        WebsocketService.sendMessage({
            command: 'unsubscribe',
            resource: `/api/boards/${this.serialNumber}`,
        });
    }

    build(object){
        this.selected = {}
        object.id ? this.id = object.id : this.id = null
        object.ip ? this.ip = object.ip : this.ip = null
        object.version ? this.version = object.version : this.version = null
        this.connected ? this.connected : false;
        object.serialNumber ? this.serialNumber = object.serialNumber : this.serialNumber = null
        if(object.scales){
            var scales = []
            object.scales.forEach((scale) => {
                scales.push(new Scale(scale))
                this.selected[scale.id] = this.selected[scale.id] ? true : false
            })
            scales.sort((a,b) => {
                return a.scaleOrder - b.scaleOrder
            })
            this.scales = scales
        }
        object.aisle ? this.aisle = object.aisle : this.aisle = null
        object.position ? this.position = object.position : this.position = null
        object.storey ? this.storey = object.storey : this.storey = null
        object.mode ? this.mode = object.mode : this.mode = null
        object.lastConnectedAt ? this.lastConnectedAt = object.lastConnectedAt : this.lastConnectedAt = null
    }

    async retrieveEntity() {
        if(this.serialNumber){
            await axios
            .get(process.env.VUE_APP_API_BASE_PATH+'/boards/'+this.serialNumber,
            {
                withCredentials: true
            })
            .then(response => {
                if(response.status == 200){
                    this.build(response.data)
                }
            })
        }
    }

    getScalesForDisplay(){
        var scales = []
        this.scales.forEach(object => {
            var foundScale = -1
            if(object.product){
                foundScale = scales.findIndex(element => element.product && element.product.barCode === object.product.barCode)
            }
            if(foundScale < 0){
                var pushScale = object
                pushScale.scaleOrder = [object.scaleOrder]
                scales.push(pushScale)
            } else {
                scales[foundScale].scaleOrder.push(object.scaleOrder)
                scales[foundScale].productCount += object.productCount
            }
        })
        this.scales = scales
    }

    countSelected(){
        var valren = 0;
        Object.keys(this.selected).forEach(element => {
            this.selected[element] ? valren++ : null
        })
        return valren
    }

    toggleAll(){
        var state = false
        Object.keys(this.selected).forEach(element => {
            this.selected[element] ? state = true : null
        })

        Object.keys(this.selected).forEach(element => {
            state ? this.selected[element] = false  : this.selected[element] = true
        })
    }

    async changeLocation(){
        await axios
        .patch(process.env.VUE_APP_API_BASE_PATH+'/boards/'+this.serialNumber+'/location', 
            {
                "aisle": this.aisle,
                "position": this.position,
                "storey": this.storey
            }
            ,
            {
                withCredentials: true,
                headers: { 'Content-Type' : 'application/merge-patch+json' } 
            })
        .then(response => {
            this.build(response.data)
        })
    }

    async toggleMode(){
        await axios
        .patch(process.env.VUE_APP_API_BASE_PATH+'/boards/'+this.serialNumber+'/mode', 
            {
                "mode": this.mode == "default" ? "settings" : "default"
            }
            ,
            {
                withCredentials: true,
                headers: { 'Content-Type' : 'application/merge-patch+json' } 
            })
        .then(response => {
            response.data.mode ? this.mode = response.data.mode : this.mode = null
        })
        WebsocketService.sendMessage({
            command: 'mode',
            resource: `/api/boards/${this.serialNumber}`,
            mode: this.mode == "settings" ? "settings" : "default",
        });

    }

    async emptySelected(){
        var values = {
            scales: []
        }
        var products = []
        Object.keys(this.selected).forEach(element => {
            if(this.selected[element]){
                values.scales.push({
                    id : element, 
                })
                var scale = this.scales.find((el) => {
                    return el.id === element
                })
                scale.products.forEach((element) => {
                    products.indexOf(element) === -1 ? products.push(element) : null;
                })
            }
        })
        products.forEach(async function(element) {
            await axios
            .patch(process.env.VUE_APP_API_BASE_PATH+'/products/'+element.barCode+'/dissociate', 
            values,
            {
                withCredentials: true,
                headers: { 'Content-Type' : 'application/merge-patch+json' } 
            })

        })
        this.toggleAll()
        this.retrieveEntity()
    }

    update(){
        this.$socket.send({
            resource: '/api/boards/'+this.serialNumber,
            command: 'update_esp',
        })
    }

    calibrateProductsSelected(){
        var values = {}
        Object.keys(this.selected).forEach(element => {
            var scaleNumber = Object.keys(this.board.scale).find(key => this.board.scale.id === key)
            this.selected[element] ? values['scale'+this.board.scale[scaleNumber].scaleOrder] = true : null
        })
        this.$socket.send({
            resource: '/api/boards/'+this.serialNumber,
            command: 'calibrate_product',
            value: values
        })
        this.toggleAll()
    }

    findScaleIndex(id){
        return this.scales.findIndex(element => {
            return element.id === id
        })
    }

    isSelectedAndConnected(){
        this.countSelected() && this.connected
    }

    autoDiscoverScales(){
        WebsocketService.sendMessage({
            resource: '/api/boards/'+this.serialNumber,
            command: 'autodiscovery'
        });
    }


}