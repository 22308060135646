<template>
    <div>
        <div class="relative mx-3 hover:text-neutral-200" @click="modelValue.length ? isOpened = !isOpened : null">
            <component :is="heroIcons['BellIcon']" fill="black" class="stroke-1 h-6 w-6" />
            <div v-show="modelValue.length"
                class="absolute -translate-y-3/4 translate-x-3/4 bottom-0 left-0 rounded-[100px] shadow-xl w-4 h-4 bg-danger-800">
            </div>
        </div>
        <transition name="fade" appear>
            <div v-show="isOpened" @click="isOpened = false" class="fixed left-0 z-20 w-full bg-alert-maintenance opacity-50"
                :class="!isMobile ? 'top-[100px] h-[calc(100vh-100px)]' : 'top-[70px] h-[calc(100vh-70px)]'"></div>
        </transition>
        <transition name="slide" appear>
            <div v-show="isOpened"
                class="bg-white w-full md:w-full lg:w-[600px] xl:w-[600px] absolute z-20 right-0 lg:right-0 overflow-scroll min-h-screen"
                :class="!isMobile ? 'top-[100px] max-h-[calc(100vh-100px)]' : 'top-[70px] max-h-[calc(100vh-70px)]'">
                <div class="flex flex-row py-1 w-full fixed bg-white items-center justify-center z-40 max-w-[600px]">
                    <ButtonVue v-if="this.filter !== ''" class="mx-1" @click="setFilter('')" :filling="'outlined'"
                        :label="null" :height="'medium'" :icon="'XMarkIcon'" :state="'danger'" />
                    <ButtonVue class="mx-1 flex-1" :class="{ 'opacity-50': this.filter !== '' && this.filter !== 'danger' }"
                        @click="setFilter('danger')" :filling="'filled'" :label="null" :icon="'XCircleIcon'"
                        :height="'medium'" :state="'danger'" />
                    <ButtonVue class="mx-1 flex-1" :class="{ 'opacity-50': this.filter !== '' && this.filter !== 'warning' }"
                        @click="setFilter('warning')" :filling="'filled'" :label="null" :icon="'ExclamationTriangleIcon'"
                        :height="'medium'" :state="'warning'" />
                    <ButtonVue class="mx-1 flex-1" :class="{ 'opacity-50': this.filter !== '' && this.filter !== 'default' }"
                        @click="null" :filling="'filled'" :label="null" :icon="'QuestionMarkCircleIcon'" :height="'medium'"
                        :state="'success'" />
                    <ButtonVue class="mx-1 flex-1" :class="{ 'opacity-50': this.filter !== '' && this.filter !== 'neutral' }"
                        @click="null" :filling="'filled'" :label="null" :icon="'Cog6ToothIcon'" :height="'medium'"
                        :state="'neutral'" />
                </div>
                <ul class="flex flex-col items-end bg-gray-50 shadow-xl md:rounded-b-lg relative top-[48px]">
                    <!-- <div v-if="Object.keys(notifications).length === 0" class="bg-white h-screen"></div> -->
                    <li v-for="(value, index) in modelValue" :key="index" class="w-full"
                        @click="redirect(value.resourceUri)">
                        <router-link :to="getLink(value.resourceUri)">
                            <div v-if="!value.seenAt"
                                class="h-full flex flex-row items-center justify-start p-3 rounded-[20px] m-1"
                                :class="getTypeColor(value.type, 'bg')">
                                <component :is="getTypeIcon(value.type)" fill="currentColor"
                                    class="mr-1 h-6 w-6 text-gray-50" />
                                <div class="flex flex-col justify-start items-start w-full ml-2">
                                    <span class="text-gray-50 font-bold"> {{ value.title }}</span>
                                    <span class="text-sm text-gray-100 pt-1">{{ value.message }}</span>
                                    <span class="text-xs text-gray-100 flex justify-end w-full pt-2">
                                        {{ new Date(value.createdAt).toLocaleDateString() }}
                                        {{ new Date(value.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}
                                    </span>
                                </div>
                            </div>
                        </router-link>
                    </li>
                </ul>
                <div v-if="!firstLoad" @click="loadMore()"
                class="bg-white text-center underline text-primary-500 p-1.5 pb-3.5 font-semibold relative top-[47px]">
                    Load More
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import * as heroIcons from '@heroicons/vue/24/outline'
import ButtonVue from './Button.vue'

export default ({
    name: 'NotificationVue',
    components: {
        ButtonVue,
    },
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
    },
    watch: {
        modelValue(){}
    },
    data() {
        return {
            heroIcons: heroIcons,
            isOpened: false,
            firstLoad: true,
            pageNumber: 1,
            filter: '',
        }
    },
    computed: {
        isMobile() {
        return this.$device.isMobile;
        },
        isPortrait() {
        return this.$device.isPortrait;
        },
    },
    methods: {
        close(){
            this.isOpened = false
        },

        async loadMore() {
            this.pageNumber++
        },

        setFilter(type) {
            if (this.filter == type) {
                this.filter = '';
            } else {
                this.filter = type;
            }

            this.notifications = [];
        },

        redirect(resourceUri) {
            var resourceArray = resourceUri.split('/')
            switch (resourceArray[2]) {
                case 'boards':
                    window.location.replace('/shelf/' + resourceArray[3])
                    break;
            }
        },

        getTypeColor(type, text) {
            const tailwindClasses = `
                text-alert-warning bg-alert-warning border-alert-warning border-l-alert-warning
                text-alert-danger bg-alert-danger border-alert-danger border-l-alert-danger
                text-alert-success bg-alert-success border-alert-success border-l-alert-success
                text-alert-maintenance bg-alert-maintenance border-alert-maintenance border-l-alert-maintenance
                text-alert-security-high bg-alert-security-high border-alert-security-high border-l-alert-security-high
                text-alert-security-low bg-alert-security-low border-alert-security-low border-l-alert-security-low
            `;
            switch (type) {
                case "warning":
                    return text + "-alert-warning"
                case "danger":
                    return text + "-alert-danger"
                case "success":
                    return text + "-alert-success"
                case "maintenance":
                    return text + "-alert-maintenance"
                case "security-high":
                    return text + "-alert-security-high"
                case "security-low":
                    return text + "-alert-security-low"
                default:
                    console.log(tailwindClasses);
            }
        },

        getTypeIcon(type) {
            switch (type) {
                case "warning":
                    return this.heroIcons['ExclamationTriangleIcon']
                case "danger":
                    return this.heroIcons['XCircleIcon']
                case "success":
                    return this.heroIcons['CheckCircleIcon']
                case "maintenance":
                    return this.heroIcons['Cog8ToothIcon']
                case "security-high":
                    return this.heroIcons['ShieldCheckIcon']
                case "security-low":
                    return this.heroIcons['LockClosedIcon']

            }
        },

        getLink(value) {
            if (value) {
                switch (value.split('/')[2]) {
                    case 'boards':
                        return '/shelf/' + value.split('/')[3]

                    default:
                        return '/dashboard'
                }
            }
        },

    }
})
</script>

<style scoped>
.slide-enter-from,
.slide-leave-to {
    transform: translate(100%, 0);
}

.slide-enter-active,
.slide-leave-active {
    transition: all .2s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
    transition: all .2s board;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.overlay {
    background: #9ca3af;
    opacity: 0.5;
}
</style>
