<template>
  <div class="static" :key="reload">
    <NavbarVue v-if="$route.path !== '/'" ref="navbar" />
    <div
      class="relative bg-gray-50"
      :class="isMobile ? 'min-h-[calc(100vh-70px)] top-[70px]' : 'min-h-[calc(100vh-100px)] top-[100px]'"
    >
      <router-view class="mx-auto" />
    </div>
  </div>
  <div v-show="userStore.connectionState === 'pending'">
    <div class="fixed top-0 left-0 z-20 h-full w-full bg-gray-500 opacity-50"></div>
    <div
      class="fixed p-10 top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4 z-50 bg-gray-50 rounded-modal shadow-xl flex flex-col justify-center items-center"
    >
      <span class="m-2">Reconnexion...</span>
      <LoadingVue />
    </div>
  </div>
  <div v-show="userStore.connectionState === 'error'">
    <div class="fixed top-0 left-0 z-20 h-full w-full bg-gray-500 opacity-50"></div>
    <div
      class="fixed p-10 top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4 z-50 bg-gray-50 rounded-modal shadow-xl flex flex-col justify-center items-center"
    >
      <span class="m-2">Une erreur est survenue...</span>
      <ButtonVue
        @click="handleRetry"
        :label="userStore.retryCount < 3 ? 'Réessayer' : 'Se reconnecter'"
        :filling="'filled'"
        :height="'medium'"
        :icon="userStore.retryCount < 3 ? 'ArrowPathIcon' : 'ArrowRightIcon'"
        :color="'primary'"
        :disabled="false"
      />
    </div>
  </div>
  <div
    v-if="userStore.displayIFrame"
    class="fixed p-10 top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4 z-50 bg-gray-50 rounded-modal shadow-xl flex flex-col justify-center items-center"
    :class="shaking"
  >
    <LoginFormVue
      @loginFailed="userStore.warnDisabled"
      @loginSuccess="handleLoginSuccess"
    />
  </div>
</template>

<script>
import NavbarVue from '@/components/groups/Navbar.vue';
import LoginFormVue from '@/components/groups/LoginForm.vue';
import LoadingVue from '@/components/elements/Loading.vue';
import ButtonVue from '@/components/elements/Button.vue';
import { useUserStore } from '@/stores/userStore';
import { onMounted, computed, ref } from 'vue';

export default {
  components: {
    NavbarVue,
    LoadingVue,
    ButtonVue,
    LoginFormVue,
  },
  setup() {
    const userStore = useUserStore();
    const reload = ref(1);

    const handleRetry = () => {
      if (userStore.retryCount < 3) {
        userStore.refreshToken();
        userStore.retryCount++;
      } else {
        userStore.goToLogin();
      }
    };

    const handleLoginSuccess = () => {
      userStore.displayIFrame = false;
      userStore.connectionState = 'success';
      reload.value++;
      userStore.fetchUser();
    };

    const isMobile = computed(() => {
      return (
        (window.innerWidth < 768) &&
        (navigator.userAgent.includes('Mobile') || window.innerWidth / window.innerHeight < 1)
      );
    });

    onMounted(() => {
      userStore.refreshToken();
      window.addEventListener('resize', () => {
        if (window.innerWidth < 768) reload.value++;
      });
    });

    return {
      userStore,
      reload,
      handleRetry,
      handleLoginSuccess,
      isMobile,
    };
  },
};
</script>
