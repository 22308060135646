<template>
  <div class="flex flex-col relative overflow-x-auto sm:rounded-lg container shadow-lg h-[calc(100vh-100px)] w-full mx-auto">
      <LoadingMolecule v-if="!loaded && !boards"/>
      <div v-else class="p-6 w-full">
          <div class="flex flex-row w-full justify-center">
              <ul class="inline-flex items-center -space-x-px">
                  <li class="border border-gray-400 rounded-l-md p-1 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300" @click="previousPage()">
                      <component :is="heroIcons['ChevronLeftIcon']" fill="none" class="stroke-1 h-7 w-7" />
                  </li>
                  <li class="border border-gray-400 p-1 text-body-2 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300">
                      <div class="h-7 min-w-[50px] px-1 flex items-center justify-center"> Allée : {{ boards[0] ? boards[0].aisle : null }} - Rang : {{ boards[0] ? boards[0].position : null }}</div>
                  </li>
                  <li class="border border-gray-400 rounded-r-md p-1 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300" @click="nextPage()">
                      <component :is="heroIcons['ChevronRightIcon']" fill="none" class="stroke-1 h-7 w-7" />
                  </li>
              </ul>
          </div>
          <div class="flex flex-col overflow-y-hidden overflow-x-auto pr-5">
            <div 
            v-for="board in boards" :key="board.id" 
            class="flex flex-row items-center justify-between min-w-[150px]">
                <div class="flex flex-col min-w-[150px] w-12%"
                v-for="section in board.scales" 
                :key="section.id"
                :class="'w-['+(section.scaleOrder.length*12)+'%]'"
                @click="board.selected[section.id] = !board.selected[section.id]"
                >
                    <div class="w-full h-full flex flex-col p-2 mb-2 border-b-4 border-b-gray-800 relative min-h-[115px]" :class="board.selected[section.id] ? 'bg-primary-100' : null">
                        <div v-for="product in section.products" :key="product.id" class="w-full h-full flex flex-col justify-end items-end">
                            <img :src="product.imageUrl" alt="" class="w-50%">
                        </div>
                        <div class="absolute -translate-y-1/4 bottom-0 left-2 flex flex-col items-center justify-center">
                            <div class="rounded-[100px] shadow-xl w-[4rem] h-[1.5rem] text-white flex flex-col items-center justify-center mb-2" :class="section.productCount && section.productCount > 0 ? 'bg-primary-800' : 'bg-danger-800'">
                                 {{ section.productCount ? section.productCount : 0 }}
                            </div>
                            <div class="rounded-[100px] shadow-xl w-[4rem] h-[1.5rem] text-black flex flex-col items-center justify-center bg-gray-300">
                                  {{ section.weight ? section.weight : 0 }}
                            </div>
                        </div>
                    </div>
                    <div class="pt-5">
                        <div v-if="details" class="w-full flex flex-col bg-gray-50 min-h-[115px] justify-start">
                          <!--<VueQrcode :value="section.id" :color="{ dark: '#000000ff', light: '#ffffffff' }" :type="'image/png'"/>-->
                            <div v-for="product in section.products" :key="product.id" class="flex flex-col">
                                <span class="font-bold text-2xl"> {{ product.brand }}</span>
                                <span class="text-l">{{ product.reference }}</span>
                                <span class="text-sm">{{ product.barCode }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col justify-center items-center w-15% h-full">
                        <router-link :to="'/shelf/'+board.serialNumber">
                            <div class="flex flex-row items-center rounded-md border border-primary-500 bg-primary-500 hover:bg-primary-400 hover:border-primary-400 shadow-xl hover:shadow-lg p-2 text-white ml-2">
                                <component :is="heroIcons['ChevronRightIcon']" 
                                fill="white" 
                                class="stroke-2 h-[1.5rem]"
                                />
                                Réglages
                            </div>
                        </router-link>
                    </div>
                </div>
          </div>
      </div>
  </div>
</template>

  
  
  <script>
  import Board from '@/utils/entities/Board';
  import LoadingVue from '../components/elements/Loading.vue';
  import BoardScales from '../components/groups/BoardScales.vue';
  import WebsocketService from '@/utils/WebsocketService';
  import * as heroIcons from '@heroicons/vue/24/outline';
  
  export default {
    name: 'ShelfViewerView',
    components: {
      LoadingVue,
      BoardScales,
    },
    data() {
      return {
        heroIcons,
        loaded: false,
        boards: [],
        subscribedboards: new Set(),
        alreadySubscribed: false,
      };
    },
    computed: {
      currentAisle() {
        return this.boards[0] ? this.boards[0].aisle : null;
      },
      currentPosition() {
        return this.boards[0] ? this.boards[0].position : null;
      },
      isMobile() {
        return this.$device.isMobile;
      },
      isPortrait() {
        return this.$device.isPortrait;
      },
    },
    async mounted() {

        WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);
        
        await this.getboards().then(async () => {
            await this.waitForWebSocketConnection().then(() => {
                this.checkAndSubscribe();
            });    
        });

        this.loaded = true;
    },
    beforeUnmount() {
      this.boards.forEach(board => {
        board.unsubscribeWebSocket()
      })
      this.subscribedboards=new Set();
    },
    watch:{
      boards:{
        handler(newboards, oldboards) {
            if(this.alreadySubscribed){
                oldboards.forEach((board) => {
                    if (!newboards.includes(board)) {
                        board.unsubscribeWebSocket();
                    this.subscribedboards.delete(board); // Remove from the Set
                    }
                });

                newboards.forEach((board) => {
                    if (!this.subscribedboards.has(board)) {
                        board.initializeWebSocketListener();
                        this.subscribedboards.add(board); // Add to the Set to track subscription
                    }
                });
            }
        },
        deep: true,
      }
    },
    methods: {
      async waitForWebSocketConnection() {
            return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (WebsocketService.isConnected) {
                clearInterval(interval);
                resolve();
                }
            }, 1000); // Check every 1 second
            });
        },
        checkAndSubscribe() {
            this.boards.forEach((board) => {
                if (!this.subscribedboards.has(board)) {
                    board.initializeWebSocketListener();
                    this.subscribedboards.add(board); // Add to the Set to track subscription
                }
            });

            this.alreadySubscribed = true
        },
      async getboards() {
        try {
          const response = await this.$axios.get(`${process.env.VUE_APP_API_BASE_PATH}/boards${window.location.search}`, {
            withCredentials: true,
          });
          if (response.status === 200) {
            this.boards = response.data['hydra:member']
              .sort((a, b) => b.storey - a.storey)
              .map(board => {
                board.scales.sort((a, b) => a.scaleOrder - b.scaleOrder);
                return new Board(board);
              });
          }
        } catch (error) {
          console.error('Error fetching boards:', error);
        }
      },

      nextPage() {
        this.$router.replace("/shelf_viewer?aisle=4&position=2");
        this.$router.go();
      },
      previousPage() {
        this.$router.replace("/shelf_viewer?aisle=4&position=1");
        this.$router.go();
      },
    },
  };
  </script>
  