<template class="mx-auto relative">
    <div
        class="relative overflow-x-auto sm:rounded-lg container shadow-lg mx-auto flex justify-center items-center w-full px-10 py-5">
        <div class="w-full lg:w-50% flex flex-col items-center justify-center">
            <div class="py-2 mt-4 w-full flex flex-row justify-between"
                :class="productMessage ? 'items-center' : 'items-end'">
                <InputVue class="w-full" :label="'Entrer ou Scanner un Code Barre'" :placeholder="'Code Barre'"
                    v-model="product.barCode" :height="'large'" :state="productState" :message="productMessage"
                    @update:modelValue="reInitMessage()" />
                <ButtonVue class="ml-2" :filling="'filled'" :label="null" :height="'large'"
                    :icon="'MagnifyingGlassIcon'" :state="'neutral'" @click="getProduct()" />
            </div>
            <div v-if="product.imageUrl" class="w-full lg:w-50% mt-10">
                <img :src="product.imageUrl" />
            </div>
            <input type="file" @input="saveFile($event)" accept="image/*,.pdf" class="block w-full text-body-5 text-neutral-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-body-5 file:font-semibold
                file:bg-primary-100 file:text-white
                hover:file:bg-primary-100 my-5
            " />
            <InputVue class="w-full mt-2" :label="'Marque'" :placeholder="'Marque'" v-model="product.brand"
                :height="'large'" :state="productBrandState" :message="productBrandMessage" />
            <InputVue class="w-full mt-2" :label="'Reference'" :placeholder="'Reference'" v-model="product.reference"
                :height="'large'" :state="productReferenceState" :message="productReferenceMessage" />
            <SelectVue class="w-full mt-2" :label="'Categorie'" v-model="product.section" :options="sections" :placeholder="'Choisir une rayon...'"
                :height="'large'" :state="'default'" />
            <InputVue class="w-full mt-2" :label="'Poids Net'" :placeholder="'Poids Net'" v-model="product.netWeight"
                :height="'large'" :state="productNetWeightState" :message="productNetWeightMessage" />
            <InputVue class="w-full mt-2" :label="'Poids'" :placeholder="'Poids'" v-model="product.weight"
                :height="'large'" :state="productWeightState" :message="productWeightMessage" />
            <InputVue class="w-full mt-2" :label="'SMP'" :placeholder="'SMP'" v-model="product.minProduct"
                :height="'large'" :state="productMinState" :message="productMinMessage" />
            <InputVue class="w-full mt-2" :label="'Delais de peremption moyen'" :placeholder="'En jours'" v-model="product.expiringOffset"
                        :height="'large'" :state="expiringOffsetState" :message="expiringOffsetMessage" />
            <CheckboxVue class="w-full mt-2" :label="'Est un produit sensible ?'"
                v-model="product.isSensitive" :height="'large'" :state="isSensitiveState"
                :message="isSensitiveMessage" />
            <ButtonVue class="w-full mt-10" :filling="'filled'" :label="'Enregistrer Produit'" :height="'large'"
                :state="'default'" @click="saveProduct()" />
        </div>
    </div>
</template>

<script>
import Product from '@/utils/entities/Product';
import InputVue from '../components/elements/Input.vue';
import ButtonVue from '../components/elements/Button.vue';
import SelectVue from '../components/elements/Select.vue';
import CheckboxVue from '../components/elements/Checkbox.vue'

export default {
    name: 'ProductCreateView',
    components: {
        InputVue,
        ButtonVue,
        SelectVue,
        CheckboxVue
    },
    data() {
        return {
            loaded: false,
            product: new Product({}),

            section: -1,
            sections: [],

            productState: 'default',
            productMessage: null,

            productBrandState: 'default',
            productBrandMessage: null,

            productReferenceState: 'default',
            productReferenceMessage: null,

            productWeightState: 'default',
            productWeightMessage: null,

            productNetWeightState: 'default',
            productNetWeightMessage: null,

            productMinState: 'default',
            productMinMessage: null,

            isSensitiveState: 'default',
            isSensitiveMessage: null,
            
            expiringOffsetState: 'default',
            expiringOffsetMessage: null,

        };
    },
    async mounted() {
        await this.loadSections()
        this.loaded = true;
    },
    methods: {
        async loadSections() {
            try {
                const response = await this.$axios.get(`${process.env.VUE_APP_API_BASE_PATH}/settings/section_names`, { withCredentials: true });
                this.sections = response.data.value[0].value.map((element) => ({
                    value: element.section,
                    name: element.section,
                }));
            } catch (error) {
                console.error('Error loading sections:', error);
            }
        },
        async saveProduct() {
            let temoin = true;
            if (!this.product.brand) {
                this.productBrandState = 'danger';
                this.productBrandMessage = 'Champ Requis';
                temoin = false;
            }
            if (!this.product.reference) {
                this.productReferenceState = 'danger';
                this.productReferenceMessage = 'Champ Requis';
                temoin = false;
            }
            if (!this.product.weight) {
                this.productWeightState = 'danger';
                this.productWeightMessage = 'Champ Requis';
                temoin = false;
            }
            if (!this.product.minProduct) {
                this.productMinState = 'danger';
                this.productMinMessage = 'Champ Requis';
                temoin = false;
            }
            if (temoin) {
                if (this.product.id) {
                    await this.product.updateProduct().then(() => {
                        window.location.replace('/products');
                    });
                } else {
                    await this.product.createProduct().then(() => {
                        window.location.replace('/products');
                    });
                }
            }
        },

        async saveFile(a) {
            let baseUrl = process.env.VUE_APP_API_BASE_PATH + '';
            baseUrl = baseUrl.substring(0, baseUrl.length - 4);
            let formData = new FormData();
            formData.append('file', a.target.files[0]);
            await this.$axios
                .post(process.env.VUE_APP_API_BASE_PATH + '/media_objects', formData, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => {
                    if (response.status == 201) {
                        console.log('Product created');
                        this.product.imageUrl = baseUrl + response.data.contentUrl;
                    }
                });
        },

        async getProduct() {
            await this.product.retrieveEntity().catch(() => {
                this.productMessage = 'Code Barre non reconnu';
                this.productState = 'danger';
            });
        },

        reInitMessage() {
            this.productMessage = null;
            this.productState = 'default';
        },
    },
};
</script>