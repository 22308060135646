<template>
    <div class="relative overflow-x-auto sm:rounded-lg container shadow-lg mx-auto w-full">
        <LoadingVue v-if="!loaded" />
        <div v-else-if="isMobile" class="flex flex-col items-center">
            <!-- Mobile View -->
            <div class="flex flex-col items-center z-40 w-full fixed bg-white border-b">
                <PaginatorAtom class="m-1 select-none"
                               :pageNumber="pageNumber"
                               :pageTotal="pageTotal"
                               @nextPage="nextPage"
                               @previousPage="previousPage" />
            </div>
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 relative top-[45px]">
                <tbody class="text-body-7 lg:text-body-3">
                    <tr v-for="board in boards" :key="board.id" class="bg-white border-b hover:bg-gray-50">
                        <td class="px-6 py-4 hidden lg:block">
                            {{ board.version }}
                        </td>
                        <td class="px-3 py-4 flex flex-row justify-between items-center">
                            <div class="flex flex-row items-center">
                                <div v-if="board.aisle && board.position && board.storey" class="flex flex-col mx-1">
                                    <div class="font-medium text-gray-900 mb-1">Allée : {{ board.aisle }} - Rang : {{ board.position }} - Étage : {{ board.storey }}</div>
                                    <div> {{ board.mode.toUpperCase() }} - MAJ : {{ timeFromNow(board.lastConnectedAt) }}</div>
                                </div>
                                <div v-else> Non renseigné</div>
                            </div>
                            <div class="flex flex-row">
                                <router-link v-if="board.aisle && board.position" :to="'/shelf_viewer?aisle='+board.aisle+'&position='+board.position">
                                    <ButtonVue class="mx-1 inline-block" :filling="'filled'" :label="null" :height="'medium'" :icon="'Square3Stack3DIcon'" :color="'primary'" />
                                </router-link>
                                <router-link :to="'/shelf/'+board.serialNumber">
                                    <ButtonVue class="mx-1 inline-block" :filling="'filled'" :label="null" :height="'medium'" :icon="'PencilIcon'" :color="'primary'" />
                                </router-link>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="flex flex-col items-center">
            <!-- Desktop View -->
            <div class="flex flex-row py-2 px-6 justify-between w-full">
                <PaginatorAtom class="select-none"
                               :pageNumber="pageNumber"
                               :pageTotal="pageTotal"
                               @nextPage="nextPage"
                               @previousPage="previousPage" />
                <div class="flex flex-row px-2">
                    <ButtonVue :filling="'filled'" :label="'SUPPRIMER'" :height="'medium'" :state="countSelected() ? 'danger' : 'disabled'" @click="deleteSelected" />
                    <ButtonVue class="mx-2" :filling="'filled'" :label="'UPDATE'" :height="'medium'" :state="countSelected() ? 'default' : 'disabled'" @click="updateSelected" />
                    <ButtonVue :filling="'filled'" :label="countSelected() ? 'UNSELECT ALL' : 'SELECT ALL'" :height="'medium'" :state="'default'" @click="toggleAll" />
                </div>
            </div>
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-body-6 lg:text-body-1 text-gray-700 uppercase bg-gray-100">
                    <tr>
                        <th></th>
                        <th scope="col" class="px-6 py-3 hidden lg:block">Version</th>
                        <th scope="col" class="px-6 py-3">Emplacement</th>
                        <th scope="col" class="px-6 py-3 hidden lg:block">Numéro de série</th>
                        <th scope="col" class="px-6 py-3">Mode</th>
                        <th scope="col" class="px-6 py-3">MAJ</th>
                        <th scope="col" class="px-6 py-3"><span class="sr-only">Edit</span></th>
                    </tr>
                </thead>
                <tbody class="text-body-7 lg:text-body-3">
                    <tr v-for="board in boards" :key="board.id" class="bg-white border-b hover:bg-gray-50">
                        <th class="px-3">
                            <CheckboxVue v-model="selected[board.serialNumber]" :height="'large'" />
                        </th>
                        <td class="px-6 py-4 hidden lg:block">{{ board.version }}</td>
                        <td class="px-6 py-4">
                            <div v-if="board.aisle && board.position && board.storey" class="flex flex-row">
                                <span>Allée : {{ board.aisle }}</span>
                                <span class="ml-2">Rang : {{ board.position }}</span>
                                <span class="ml-2">Étage : {{ board.storey }}</span>
                            </div>
                            <span v-else> Non renseigné</span>
                        </td>
                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap hidden lg:block">{{ board.serialNumber }}</th>
                        <td class="px-6 py-4">{{ board.mode.toUpperCase() }}</td>
                        <td scope="col" class="px-6 py-3">{{ timeFromNow(board.lastConnectedAt) }}</td>
                        <td class="px-6 py-4 text-right">
                            <router-link v-if="board.aisle && board.position" :to="'/shelf_viewer?aisle='+board.aisle+'&position='+board.position">
                                <a href="#" class="font-medium text-primary-800 dark:text-primary-500 hover:underline">ShelfViewer</a>
                            </router-link>
                            <router-link :to="'/shelf/'+board.serialNumber">
                                <a href="#" class="font-medium text-primary-800 dark:text-primary-500 hover:underline ml-2">Edit</a>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import CheckboxVue from '../components/elements/Checkbox.vue';
import ButtonVue from '../components/elements/Button.vue';
import LoadingVue from '../components/elements/Loading.vue';
import PaginatorAtom from '../components/elements/Paginator.vue';
import WebsocketService from '@/utils/WebsocketService';
import Board from '@/utils/entities/Board';

export default {
    name: 'ShelvesView',
    components: {
        LoadingVue,
        PaginatorAtom,
        CheckboxVue,
        ButtonVue,
    },
    data() {
        return {
            loaded: false,
            query: process.env.VUE_APP_API_BASE_PATH + '/boards',
            dynamicQuery: process.env.VUE_APP_API_BASE_PATH + '/boards',
            boards: [],
            selected: {},
            pageNumber: 1,
            pageTotal: 1,
            checkboxes: [],
            subscribedboards: new Set(),
            alreadySubscribed: false,
        };
    },
    computed: {
    isMobile() {
      return this.$device.isMobile;
    },
    isPortrait() {
      return this.$device.isPortrait;
    },
  },
    async mounted() {

        WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);
        
        await this.getBoards().then(async () => {
            this.initializeSelection();
            await this.waitForWebSocketConnection().then(() => {
                this.checkAndSubscribe();
            });    
        });

        WebsocketService.addListener((type, data) => {
            if (type === 'message') {
                this.handleWebSocketMessage(data);
            }
        });
        this.loaded = true;
    },
    beforeUnmount() {
      this.boards.forEach(board => {
        board.unsubscribeWebSocket()
      })
      WebsocketService.sendMessage({
            command: 'unsubscribe',
            resource: '/api/boards',
        });
        this.subscribedboards=new Set();
    },
    watch:{
      boards:{
        handler(newboards, oldboards) {
            if(this.alreadySubscribed){
                oldboards.forEach((board) => {
                    if (!newboards.includes(board)) {
                        board.unsubscribeWebSocket();
                    this.subscribedboards.delete(board); // Remove from the Set
                    }
                });

                newboards.forEach((board) => {
                    if (!this.subscribedboards.has(board)) {
                        board.initializeWebSocketListener();
                        this.subscribedboards.add(board); // Add to the Set to track subscription
                    }
                });
            }
        },
        deep: true,
      }
    },
    methods: {
        async waitForWebSocketConnection() {
            return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (WebsocketService.isConnected) {
                clearInterval(interval);
                resolve();
                }
            }, 1000); // Check every 1 second
            });
        },
        checkAndSubscribe() {
            this.boards.forEach((board) => {
                if (!this.subscribedboards.has(board)) {
                    board.initializeWebSocketListener();
                    this.subscribedboards.add(board); // Add to the Set to track subscription
                }
            });
        
            WebsocketService.sendMessage({
                command: 'subscribe',
                resource: '/api/boards',
            });
            this.alreadySubscribed = true
        },
        handleWebSocketMessage(data) {
            if (data && data.resource && data.resource == '/api/boards') {
                this.getboards();
            } else {
                console.warn('Invalid data or resource format');
            }
        },

        async getBoards() {
            try {
                const response = await this.$axios.get(this.dynamicQuery, { withCredentials: true });
                if (response.status === 200) {
                    this.boards = [];
                    response.data['hydra:member'].forEach(board => this.boards.push(new Board(board)))
                    this.updatePagination(response.data['hydra:view']);
                }
            } catch (error) {
                console.error('Failed to fetch boards:', error);
                this.boards = [];
            }
        },
        initializeSelection() {
            this.boards.forEach(element => {
                this.selected[element.serialNumber] = false;
            });
        },
        updatePagination(viewData) {
            if (viewData) {
                this.pageNumber = parseInt(new URLSearchParams(viewData["@id"].split('?')[1]).get("page"));
                this.pageTotal = parseInt(new URLSearchParams(viewData["hydra:last"].split('?')[1]).get("page"));
            }
        },
        async nextPage() {
            if (this.pageNumber < this.pageTotal) {
                this.dynamicQuery = `${this.query}?page=${this.pageNumber + 1}`;
                await this.getboards();
            }
        },
        async previousPage() {
            if (this.pageNumber > 1) {
                this.dynamicQuery = `${this.query}?page=${this.pageNumber - 1}`;
                await this.getboards();
            }
        },
        countSelected() {
            return Object.values(this.selected).filter(Boolean).length;
        },
        async updateSelected() {
            // Update logic for selected items
        },
        async deleteSelected() {
            if (confirm("Êtes-vous sûr de vouloir supprimer les linéaires séléctionnés ? Cette action est irréversible")) {
                for (const serialNumber in this.selected) {
                    if (this.selected[serialNumber]) {
                        await this.$axios.delete(`${process.env.VUE_APP_API_BASE_PATH}/boards/${serialNumber}`, { withCredentials: true });
                    }
                }
                await this.getboards(); // Refresh the list after deletion
            }
        },
        toggleAll() {
            const allSelected = Object.values(this.selected).every(val => val);
            Object.keys(this.selected).forEach(serialNumber => {
                this.selected[serialNumber] = !allSelected;
            });
        },
        timeFromNow(datetime) {
            if (!datetime) return "?";
            const secondsFromNow = Math.floor((new Date() - new Date(datetime)) / 1000);
            if (secondsFromNow < 60) return `${secondsFromNow} seconds`;
            const minutesFromNow = Math.floor(secondsFromNow / 60);
            if (minutesFromNow < 60) return `${minutesFromNow} minutes`;
            const hoursFromNow = Math.floor(minutesFromNow / 60);
            if (hoursFromNow < 24) return `${hoursFromNow} heures`;
            return `${Math.floor(hoursFromNow / 24)} jours`;
        },
    },
};
</script>
