<template>
    <div class="relative overflow-x-auto sm:rounded-lg container shadow-lg mx-auto w-full">
      <LoadingVue v-if="!loaded" />
      <div v-else>
        <!-- Mobile View -->
        <div v-if="isMobile" class="flex flex-col items-center h-[100vh] pb-32 overflow-y-auto overscroll-contain">
          <!-- Mobile Top Controls -->
          <div class="flex flex-row py-1 w-full fixed bg-white items-center justify-center z-40">
            <PaginatorVue class="m-1 select-none" 
              :pageNumber="parameters.page" 
              :pageTotal="pageTotal"               
              @nextPage="nextPage()" 
              @previousPage="previousPage()" 
            />
            <router-link to="/product_create">
              <ButtonVue class="mx-1" :filling="'filled'" :icon="'PlusIcon'" :height="'medium'" :state="'default'" />
            </router-link>
            <ButtonVue class="mx-1" @click="modalOpen = true" :filling="'filled'" :icon="'FunnelIcon'" :height="'medium'" :state="'default'" />
            <ButtonVue class="mx-1" @click="log('click')" :filling="'filled'" :icon="'ClipboardDocumentCheckIcon'" :height="'medium'" :state="'default'" />
          </div>
  
          <!-- Product Table for Mobile -->
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 relative top-[55px]">
            <tbody>
              <tr v-for="product in products" :key="product.id" class="bg-white border-b hover:bg-gray-50">
                <td class="px-2 py-4 font-medium text-gray-900">

                  <div class="flex flex-row pb-3 items-start">
                    <ButtonVue class="mx-1 inline-block" :filling="'outlined'" :label="productLabel(product)" :height="'medium'" :state="calculateButtonState(product.totalCount, product.minProduct)" />
                    <div class="inline-block flex-wrap flex-grow mx-2">
                      <div class="wrap-text">{{ product.brand }}</div>
                      <div class="wrap-text font-light">{{ product.reference }}</div>
                    </div>
                    <div class="flex flex-row">
                      <router-link :to="'/product/' + product.barCode">
                        <ButtonVue v-if="!startedSelection" class="mx-1 inline-block" :filling="'filled'" :height="'medium'" :icon="'PencilIcon'" :color="'primary'" />
                      </router-link>
                      <ButtonVue class="mx-1 inline-block" @click="handleOpenProduct(product.barCode)" :filling="'outlined'" :height="'medium'" :icon="'ChevronDownIcon'" :state="'neutral'" />
                    </div>
                  </div>
  
                  <!-- Additional Info -->
                  <div v-if="additionalInfoVisible[product.barCode]" class="pt-4 border-t border-gray-300">
                    <div v-for="(setting, index) in filteredSettings" :key="index">
                      <div v-if="setting.column === 'barCode'">Code bar : {{ product.barCode }}</div>
                      <div v-if="setting.column === 'section'">Rayon : {{ product.section }}</div>
                      <div v-if="setting.column === 'weight'">Poids : {{ product.weight }}g</div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
  
          <!-- Filter Modal -->
          <BottomSheetVue v-if="modalOpen" @closeModal="modalOpen = false">
            <template v-slot:body>
              <div class="flex flex-col items-center gap-y-2 py-5">
                <div class="flex flex-row">
                  <ButtonVue class="mx-1" @click="resetFilters" :filling="'outlined'" :icon="'XMarkIcon'" :height="'medium'" :state="'danger'" />
                </div>
                <AutocompleteVue class="mx-1 w-[80%]" v-model="parameters.brandAndRef" :height="'medium'" :placeholder="'Marque, Réf'" @update:modelValue="getProducts" />
                <SelectVue class="mx-1 w-[80%]" v-model="parameters.section" :options="sections" :placeholder="'Rayon'" :height="'medium'" :state="'default'" @update:modelValue="getProducts" />
              </div>
            </template>
          </BottomSheetVue>
        </div>
  
        <!-- Desktop View -->
        <div v-else>
            <!-- Desktop Top Controls -->
            <div class="flex flex-row py-2 px-6 justify-between flex-wrap w-full">
                <PaginatorVue
                class="m-1 select-none" 
                :pageNumber="parameters.page" 
                :pageTotal="pageTotal"               
                @nextPage="nextPage()" 
                @previousPage="previousPage()" 
                />
                <!-- Filters and Controls -->
                <div class="flex flex-row w-50% min-w-[400px] my-1">
                <ButtonVue 
                    class="mx-1" 
                    @click="resetFilters" 
                    :filling="'outlined'" 
                    :icon="'XMarkIcon'" 
                    :height="'medium'" 
                    :state="'danger'" 
                />
                <AutocompleteVue 
                    class="mx-1 w-50%" 
                    v-model="parameters.brandAndRef" 
                    :height="'medium'"
                    :placeholder="'Marque Référence'" 
                    @update:modelValue="getProducts" 
                />
                <SelectVue 
                    class="mx-1 w-50%" 
                    v-model="parameters.section" 
                    :options="sections"
                    :placeholder="'Choisir une rayon...'" 
                    :height="'medium'" 
                    :state="'default'"
                    @update:modelValue="getProducts" 
                />
                </div>
                <!-- Create Product Button -->
                <div class="flex flex-row m-1">
                  <ButtonVue 
                    :filling="'filled'" 
                    :label="'SUPPRIMER'" 
                    :height="'medium'" 
                    :state="countSelected() ? 'danger' : 'disabled'" 
                    @click="deleteSelected()" />
                  <router-link to="/product_create">
                      <ButtonVue 
                      class="ml-2" 
                      :filling="'filled'" 
                      :label="'CRÉER'" 
                      :height="'medium'" 
                      :state="'default'" 
                      />
                  </router-link>
                </div>
            </div>

            <!-- Product Table for Desktop -->
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-100">
                <tr>
                    <th class="px-3"></th>
                    <th>ÉTAT</th>
                    <th 
                    v-for="(setting, index) in settings.filter(s => s.state)" 
                    :key="index"
                    class="px-6 py-3"
                    >
                      <span class="inline-flex select-none" @click="toggleSettingOrder(setting)">
                        {{ setting.title }}
                        <span :class="setting.changed ? 'text-success-500' : 'text-neutral-500'">
                          <component v-if="setting.order"
                          :is="setting.order == 'desc' ? heroIcons['ChevronDownIcon'] : heroIcons['ChevronUpIcon']"
                          fill="currentColor" 
                          class="stroke-1 h-4 w-4 ml-2"/>
                        </span>
                      </span> 
                    </th>
                    <th class="px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr 
                    v-for="product in products" 
                    :key="product.id" 
                    class="bg-white border-b hover:bg-gray-50"
                    >
                    <th class="px-3">
                    <CheckboxVue v-model="selected[product.barCode]" :height="'large'" />
                    </th>
                    <th>
                      <span class="relative flex h-5 w-5">
                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
                          v-if="product.totalCount < product.minProduct || product.totalCount == 'N/A'"
                          :class="productState(product)"></span>
                        <span class="relative inline-flex rounded-full h-5 w-5"
                          :class="productState(product)"></span>
                      </span>
                    </th>
                    <td 
                    v-for="(setting, index) in settings.filter(s => s.state)" 
                    :key="index"
                    class="px-6 py-4"
                    >
                    {{ product[setting.column] !== -1 ? product[setting.column] : ""}}
                    </td>
                    <td class="px-6 py-4 text-right">
                    <router-link :to="'/product/' + product.barCode">
                        <ButtonVue 
                        :filling="'text'" 
                        :label="'Edit'" 
                        :color="'primary'" 
                        :height="'medium'" 
                        class="font-medium hover:underline ml-2" 
                        />
                    </router-link>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>

      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import * as heroIconsOutline from '@heroicons/vue/24/outline'
  import LoadingVue from '../components/elements/Loading.vue';
  import ButtonVue from '../components/elements/Button.vue';
  import CheckboxVue from '../components/elements/Checkbox.vue';
  import PaginatorVue from '../components/elements/Paginator.vue';
  import AutocompleteVue from '../components/elements/Autocomplete.vue';
  import SelectVue from '../components/elements/Select.vue';
  import BottomSheetVue from '../components/containers/BottomSheet.vue';
  import WebsocketService from '@/utils/WebsocketService';
  import Product from '@/utils/entities/Product';
  
  export default {
    name: 'ProductsView',
    components: {
      LoadingVue,
      PaginatorVue,
      ButtonVue,
      CheckboxVue,
      AutocompleteVue,
      SelectVue,
      BottomSheetVue,
    },
    data() {
      return {
        heroIcons: heroIconsOutline,
        loaded: false,
        products: [],
        settings: [],
        selected: [],
        pageTotal: 1,
        sections: [],
        additionalInfoVisible: {},
        startedSelection: false,
        modalOpen: false,
        parameters: {
          page: 1,
          section: -1,
          brandAndRef: '',
        },
        subscribedProducts: new Set(),
        productsLoaded: false,
        alreadySubscribed: false,
      };
    },
    computed: {
      filteredSettings() {
        return this.settings.filter((s) => s.state);
      },
      isMobile() {
        return this.$device.isMobile;
        },
        isPortrait() {
        return this.$device.isPortrait;
        },
    },
    async created() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_PATH}/settings/product_columns`, { withCredentials: true });
        this.settings = response.data.value[0].value;
        this.settings.forEach((setting) => {
          if(['totalCount'].includes(setting.column)) setting.order = 'desc'
          
        })
      } catch (error) {
        console.error('Error loading settings:', error);
      }
    },

    watch:{
      products:{
        handler(newProducts, oldProducts) {
          if(this.alreadySubscribed){
            oldProducts.forEach((product) => {
              if (!newProducts.includes(product)) {
                product.unsubscribeWebSocket();
                this.subscribedProducts.delete(product);
              }
            });

            newProducts.forEach((product) => {
              if (!this.subscribedProducts.has(product)) {
                product.initializeWebSocketListener();
                this.subscribedProducts.add(product);
              }
            });
          }
        },
        deep: true,
      }
    },

    async mounted() {
      this.setupParameters();
      WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);

      await this.loadSections();
      await this.getProducts().then(async () => {
        this.productsLoaded = true;
        this.initializeSelection();
        await this.waitForWebSocketConnection().then(() => {
          this.checkAndSubscribe();
        });
      });

      WebsocketService.addListener((type, data) => {
        if (type === 'message') {
          this.handleWebSocketMessage(data);
        }
      });
      this.$emit('loaded');
    },

    beforeUnmount() {
      this.products.forEach(product => {
          product.unsubscribeWebSocket()
      })
      WebsocketService.sendMessage({
          command: 'unsubscribe',
          resource: '/api/products',
        });
        this.subscribedProducts=new Set();
    },

    methods: {
      productState(product){
        if(product.totalCount == 0 || product.totalCount == "N/A"){
          return 'bg-danger-500'
        } else if (product.totalCount < product.minProduct && product.totalCount !== 0){
          return 'bg-warning-500'
        } 
        return 'bg-success-500'
      },
      async waitForWebSocketConnection() {
        return new Promise((resolve) => {
          const interval = setInterval(() => {
            if (WebsocketService.isConnected) {
              clearInterval(interval);
              resolve();
            }
          }, 1000); // Check every 1 second
        });
      },
      checkAndSubscribe() {
        this.products.forEach((product) => {
            if (!this.subscribedProducts.has(product)) {
              product.initializeWebSocketListener();
              this.subscribedProducts.add(product);
            }
          });

        WebsocketService.sendMessage({
          command: 'subscribe',
          resource: '/api/products',
        });
        this.alreadySubscribed = true
      },
      setupParameters() {
        const router = this.$router.currentRoute.value.query;
        this.parameters.page = parseInt(router.page) || 1;
        this.parameters.section = parseInt(router.section) || -1;
        this.parameters.brandAndRef = router.brandAndRef || '';
      },
      async loadSections() {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_PATH}/settings/section_names`, { withCredentials: true });
          this.sections = response.data.value[0].value.map((element) => ({
            value: element.section,
            name: element.section,
          }));
        } catch (error) {
          console.error('Error loading sections:', error);
        }
      },
      async getProducts() {
        let url = `${process.env.VUE_APP_API_BASE_PATH}/products?page=${this.parameters.page}`;
        if (this.parameters.brandAndRef) {
          url += `&simplesearch=${this.parameters.brandAndRef}`;
        }
        if (this.parameters.section !== -1) {
          url += `&section=${this.parameters.section}`;
        }
        this.settings.forEach((setting) => {
          url += `&order[${setting.column}]=${setting.order}`;
        })
  
        try {
          const response = await axios.get(url, { withCredentials: true });
          if (response.status === 200) {
            this.products = [];
            response.data['hydra:member'].forEach(product => this.products.push(new Product(product)))
            this.handlePagination(response.data);
            this.loaded = true;
            this.setNewPath();
          }
        } catch (error) {
          console.error('Error fetching products:', error);
          this.products = [];
        }
      },
      handlePagination(data) {
        if(data['hydra:view'] && data['hydra:totalItems'] > 30){
            this.parameters.page = parseInt((JSON.parse('{"' + decodeURI(data['hydra:view']["@id"].split('?')[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}'))["page"])
            this.parameters.page = !this.parameters.page ? 1 : this.parameters.page
            data['hydra:view']["hydra:last"] ? this.pageTotal = parseInt((JSON.parse('{"' + decodeURI(data['hydra:view']["hydra:last"].split('?')[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}'))["page"]) : this.pageTotal = this.parameters.page
        } else {
          this.parameters.page = 1;
          this.pageTotal = 1;
        }
       
      },
      async nextPage(){
          if(this.parameters.page < this.pageTotal){
              this.parameters.page++
              this.getProducts()
          }
      },
      async previousPage(){
          if(this.parameters.page > 1){
              this.parameters.page--
              this.getProducts()
          }
      },
      handleWebSocketMessage(data) {
            if (data && data.resource && data.resource == '/api/products') {
                this.getProducts();
            } 
        },
      initializeSelection() {
        this.products.forEach((element) => {
          this.selected[element.barCode] = false;
        });
      },
      log(action) {
        console.log(action);
      },
      toggleSettingOrder(setting){
        setting.order = setting.order === 'desc' ? 'asc' : 'desc';
        setting.changed = true
        this.getProducts()
      },
      resetFilters() {
        this.parameters.section = -1;
        this.parameters.brandAndRef = null;
        this.settings.forEach((setting) => {
          if(['createdAt', 'totalAfterGlobal', 'totalAfterByLocation', 'differenceProduct', 'differenceProduct'].includes(setting.column)) {
            setting.order = 'desc'
            setting.changed = false
          }
        })
        this.getProducts();
      },
      handleOpenProduct(productId) {
        this.additionalInfoVisible[productId] = !this.additionalInfoVisible[productId];
      },
      calculateButtonState(totalCount, minProduct) {
        if (totalCount === 0) {
          return 'danger';
        } else if (minProduct !== 'N/A' && totalCount <= minProduct) {
          return 'warning';
        } else if (minProduct === 'N/A' && totalCount <= 5) {
          return 'warning';
        }
        return 'neutral';
      },
      productLabel(product) {
        return product.totalCount !== undefined ? product.totalCount.toString() : 'N/A';
      },
      setNewPath() {
        const newQuery = {};
        if (this.parameters.page && this.parameters.page !== 1) newQuery.page = this.parameters.page;
        if (this.parameters.section && this.parameters.section !== -1) newQuery.section = this.parameters.section;
        if (this.parameters.brandAndRef) newQuery.brandAndRef = this.parameters.brandAndRef;
  
        const fullPath = `/products${Object.keys(newQuery).length > 0 ? '?' + new URLSearchParams(newQuery).toString() : ''}`;
        window.localStorage.setItem('lastPath', fullPath);
      },
      countSelected() {
          return Object.values(this.selected).filter(Boolean).length;
      },
      async deleteSelected() {
          if (confirm("Êtes-vous sûr de vouloir supprimer les produits séléctionnés ? Cette action est irréversible")) {
              for (const barCode in this.selected) {
                  if (this.selected[barCode]) {
                      await this.$axios.delete(`${process.env.VUE_APP_API_BASE_PATH}/products/${barCode}`, { withCredentials: true });
                  }
              }
              await this.getProducts(); // Refresh the list after deletion
          }
      },
    },
  };
  </script>
  