<template>
    <div>
        <transition name="fade" appear>
            <div
            class="w-full fixed z-40 left-0 overlay" 
            :class="!isMobile ? 'h-[calc(100vh-100px)] top-[100px]' : 'h-[calc(100vh-70px)] top-[70px]'"
            @click="$emit('closeModal')"
            ></div>
        </transition>
        <transition name="slide" appear>
                <div 
                class="fixed z-50 right-0"
                :class="!isMobile ? 'h-[calc(100vh-100px)] top-[100px] w-'+width+'%' : 'h-[calc(100vh-70px)] top-[70px] w-75%'"
                >
                    <div class="h-full w-full bg-gray-50 shadow-lg relative z-50">
                        <div class="absolute bg-gray-50 text-gray-500 top-[1rem] w-[2rem] h-[5rem] left-[calc(-2rem)] rounded-l-lg flex items-center justify-center z-50" @click="$emit('closeModal')">
                            <component :is="heroIcons['ChevronRightIcon']" fill="none" class="stroke-1 h-7 w-7" />
                        </div>
                        <slot name="body">
                            This is the default body!
                        </slot>
                    </div>
                </div>
        </transition>
    </div>
</template>

<script>
import * as heroIcons from '@heroicons/vue/24/outline'

export default {
  name: 'RightSheet',
  emits:['closeModal'],
  props:{
    width:{
        type: Number,
        default: 34
    }
  },
  data(){
    return {
        heroIcons: heroIcons,
        traqr: null,
    }
  },
  computed: {
        isMobile() {
        return this.$device.isMobile;
        },
        isPortrait() {
        return this.$device.isPortrait;
        },
    },
}
</script>

<style scoped>

.slide-enter-from,
.slide-leave-to {
    transform: translate(100%, 0);
}

.slide-enter-active,
.slide-leave-active {
    transition: all .4s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
  transition: all .4s board;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.overlay {
    background: #9ca3af;
    opacity: 0.5;
}

</style>