<template>
    <div class="flex flex-row overflow-y-hidden overflow-x-auto pr-5 w-full">
      <div
        v-for="scale in scales"
        :key="scale.id"
        class="flex flex-col min-w-[150px] w-12%"
        :class="'w-['+(scale.scaleOrder.length*12)+'%]'"
      >
        <div class="w-full h-full flex flex-col p-2 mb-2 border-b-4 border-b-gray-800 relative min-h-[115px]">
          <div
            v-for="product in scale.products"
            :key="product.id"
            class="w-full h-full flex flex-col justify-end items-end"
          >
            <img :src="product.imageUrl" alt="" class="w-50%" />
          </div>
          <div class="absolute -translate-y-1/4 bottom-0 left-2 flex flex-col items-center justify-center">
            <div
              class="rounded-[100px] shadow-xl w-[4rem] h-[1.5rem] text-white flex flex-col items-center justify-center mb-2"
              :class="scale.productCount && scale.productCount > 0 ? 'bg-primary-800' : 'bg-danger-800'"
            >
              {{ scale.productCount ? scale.productCount : 0 }}
            </div>
            <div class="rounded-[100px] shadow-xl w-[4rem] h-[1.5rem] text-black flex flex-col items-center justify-center bg-gray-300">
              {{ scale.weight ? scale.weight : 0 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BoardScales',
    props: {
      scales: {
        type: Array,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any specific styles here */
  </style>
  