import axios from 'axios';
export default class API {
  constructor() { }

  /**
 * @description: récuperer la légende et la carte de la base de donnée
 * @return {Promise<Object>}: les carrés déja placés sur la carte et la légende
 */
  async getMap() {
    try {
      const response = await axios.get(process.env.VUE_APP_API_BASE_PATH + "/settings/map_display", { withCredentials: true });
      let squares = response.data.value.map_content;
      let size = response.data.value.map_size;

      if (squares == null) {
        squares = [];
      }

      return { squares, size };
    } catch (error) {
      console.error("Error fetching map data:", error);
      throw error; // Re-throw the error to handle it at the calling site
    }
  }

  /**
   * @description: récuperer les alertes pour chaque emplacement en 2D
   * @return {Promise<Object>}: les alerts triées par emplacement 2D
   */
  async getMapAlerts() {
    try {
      const response = await axios.get(process.env.VUE_APP_API_BASE_PATH + "/shelves_map", { withCredentials: true });
      
      return response.data;
    } catch (error) {
      console.error("Error fetching shelves_map:", error);
      throw error; // Re-throw the error to handle it at the calling site
    }
  }

  /**
   * @description: récuperer la liste des lineaires de la base de données
   * @return {Array} liste des lineaires
   */
  async getboard() {
    let board = [];
    await axios
      .get(process.env.VUE_APP_API_BASE_PATH + "/shelves_map", { withCredentials: true })
      .then(response => {
        board = response.data
      });
    return board;
  }

  /**
   * @description: récuperer la liste des catégories de la base de données
   * @return {Array} liste des sections
   */
  async getCategories() {
    try {
      const response = await axios.get(process.env.VUE_APP_API_BASE_PATH + "/settings/section_names", { withCredentials: true });
      const sectionNames = response.data.value[0].value;

      return sectionNames;
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  }


  /**
   * @description: recuperer la liste des cubes de la base de données et les ajouter dans la liste des cubes deja existants
   * @param {*} cubes 
   * @param {*} refs 
   * @returns 
   */
  getCube(cubes, refs) {
    let listeCubes = refs.cube;
    let Data = [];
    listeCubes.forEach(cube => {
      let rect = cube.getNode();
      let pos = rect.position();
      Data.push({
        "id": rect.attrs.id,
        "x": pos.x,
        "y": pos.y,
        "fill": rect.fill(),
        "draggable": rect.attrs.draggable,
        "isInLegend": cubes[rect.index].isInLegend,
        "section": cubes[rect.index].section,
        "alerts": cubes[rect.index].alerts,
      })
    });
    return Data;
  }



  /**
   * @description: cette function permet de sauvegarder les information de la map dans la base de donnée
   * @param {*} cubes 
   */
  async save(cubes, size) {
    try {
      const response = await axios.patch(
        process.env.VUE_APP_API_BASE_PATH + '/settings/map_display',
        { value: { map_size: size, map_content: cubes } },
        { withCredentials: true, headers: { 'Content-Type': 'application/merge-patch+json' } }
      );
      return response.status; 
    } catch (error) {
      console.error('Error saving grid:', error);
      throw error; 
    }
  }

}