<template>
    <transition name="fade" appear>
        <div
        class="w-full fixed z-10 left-0 overlay" 
        :class="!isMobile ? 'h-[calc(100vh-100px)] top-[100px]' : 'h-[calc(100vh-70px)] top-[70px]'"
        @click="$emit('closeModal')"
        ></div>
    </transition>
    <transition name="slide" appear>
        <div 
        class="fixed z-20 right-0 bottom-0"
        :class="!isMobile ? 'w-34%' : 'w-full'"
        >
            <div class="h-full w-full bg-gray-50 shadow-lg relative">
                <div class="absolute bg-gray-50 text-gray-500 top-[calc(-2rem)] w-[5rem] h-[2rem] right-[1rem] rounded-t-lg flex items-center justify-center" @click="$emit('closeModal')">
                    <component :is="heroIcons['ChevronDownIcon']" fill="none" class="stroke-1 h-7 w-7" />
                </div>
                <slot name="body">
                    This is the default body!
                </slot>
            </div>
        </div>
    </transition>
</template>

<script>
import * as heroIcons from '@heroicons/vue/24/outline'

export default {
  name: 'BottomSheetVue',
  emits:['closeModal'],
  data(){
    return {
        heroIcons: heroIcons,
        traqr: null,
    }
  },
  computed: {
        isMobile() {
        return this.$device.isMobile;
        },
        isPortrait() {
        return this.$device.isPortrait;
        },
    },
}
</script>

<style scoped>

.slide-enter-from,
.slide-leave-to {
    transform: translate(0, 100%);
}

.slide-enter-active,
.slide-leave-active {
    transition: all .4s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
  transition: all .4s board;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.overlay {
    background: #9ca3af;
    opacity: 0.5;
}

</style>