<template>
    <div class="w-full relative">
        <img :src="dashboard" alt="">
    </div>
</template>

<script>


export default ({
    name: 'DashboardView',
    data(){
        return {
            dashboard: require('@/assets/dashboard.png'),
        }
    },  
    created(){
        this.$emit('loaded')
    },
})
</script>