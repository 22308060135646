<template>
    <div class="relative sm:rounded-lg overflow-hidden container shadow-lg h-full w-full mx-auto">
        <LoadingVue v-if="!loaded" class="relative"/>
        <div v-else-if="isMobile" class="w-full h-full flex flex-col pl-5 ">
            <div class="flex flex-row justify-center md:justify-between flex-wrap py-5">
                <span class="font-bold text-l lg:text-2xl ml-2 "> {{ board.serialNumber }}</span>
                <span>Allée : {{ board.aisle }} Rang : {{ board.position }} Étage : {{ board.storey }}</span>
            </div>
            <div class="flex flex-row overflow-y-hidden overflow-x-auto pr-5">
                <div class="flex flex-col min-w-[150px] w-12%"
                v-for="section in board.scales" 
                :key="section.id"
                :class="'w-['+(section.scaleOrder.length*12)+'%]'"
                @click="board.selected[section.id] = !board.selected[section.id]"
                >
                    <div class="w-full h-full flex flex-col p-2 mb-2 border-b-4 border-b-gray-800 relative min-h-[115px]" :class="board.selected[section.id] ? 'bg-primary-100' : null">
                        <div v-for="product in section.products" :key="product.id" class="w-full h-full flex flex-col justify-end items-end">
                            <img :src="product.imageUrl" alt="" class="w-50%">
                        </div>
                        <div class="absolute -translate-y-1/4 bottom-0 left-2 flex flex-col items-center justify-center">
                            <div class="rounded-[100px] shadow-xl w-[4rem] h-[1.5rem] text-white flex flex-col items-center justify-center mb-2" :class="section.productCount && section.productCount > 0 ? 'bg-primary-800' : 'bg-danger-800'">
                                 {{ section.productCount ? section.productCount : 0 }}
                            </div>
                            <div class="rounded-[100px] shadow-xl w-[4rem] h-[1.5rem] text-black flex flex-col items-center justify-center bg-gray-300">
                                  {{ section.weight ? section.weight : 0 }}
                            </div>
                        </div>
                    </div>
                    <div class="pt-5">
                        <div v-if="details" class="w-full flex flex-col bg-gray-50 min-h-[115px] justify-start">
                            <VueQrcode :value="section.id" :color="{ dark: '#000000ff', light: '#ffffffff' }" :type="'image/png'"/>
                            <div v-for="product in section.products" :key="product.id" class="flex flex-col">
                                <span class="font-bold text-2xl"> {{ product.brand }}</span>
                                <span class="text-l">{{ product.reference }}</span>
                                <span class="text-sm">{{ product.barCode }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      <ModalVue
      v-show="associateLocation"
      :title="'Associer un Emplacement au Linéaire'"
      :confirmButtonLabel="'CONFIRMER'"
      @closeModal="associateLocation = false"
      @confirm="changeLocation()"
      >
          <template v-slot:body>
              <div class="flex flex-col flex-wrap justify-around items-center p-4">
                  <InputVue class="w-25% min-w-[200px] mb-5"
                  v-model="board.aisle" 
                  :height="'large'" 
                  :label="'Allée'" 
                  :placeholder="'Numéro de l\'allée'" 
                  :state="stateAisle" 
                  :message="helperAisle"/>
                  <InputVue class="w-25% min-w-[200px] mb-5"
                  v-model="board.position" 
                  :height="'large'"
                  :label="'Rang'" 
                  :placeholder="'Numéro de rang'" 
                  :state="statePosition" 
                  :message="helperPosition"/>
                  <InputVue class="w-25% min-w-[200px]"
                  v-model="board.storey" 
                  :height="'large'" 
                  :label="'Étage'" 
                  :placeholder="'Numéro d\'étage'" 
                  :state="stateStorey" 
                  :message="helperStorey"/>
              </div>
          </template>
      </ModalVue>
      <ModalVue
      v-show="calibrateScales"
      :title="'Calibrer les Scales sélectionnés'"
      :confirmButtonLabel="'CALIBRER ('+board.countSelected()+')'"
      @closeModal="calibrateScales = false"
      @confirm="calibrateScalesSelected(calibratingWeight)"
      >
          <template v-slot:body>
              <div class="flex flex-col justify-center items-center p-4">
                  <span>
                      Avant la calibration, vous pouver effectuer une tare...
                  </span>
                  <span>
                      Pour se faire, assurez-vous qu'il n'y ait plus aucun produit sur les scales sélectionnés
                  </span>
                  <span class="flex flex-row w-85% items-center justify-center my-2">
                      Puis cliquez sur 
                  </span>
                  <ButtonVue class="w-[200px] mt-2"
                      :filling="'filled'"
                      :label="'TARE ('+ board.countSelected()+')'"
                      :height="'medium'"
                      :state="board.countSelected() ? 'default' : 'disabled'"
                      @click="tareScalesSelected()"
                      />
                  <span class="text-xl font-bold mt-2">
                      Sinon
                  </span>
                  <span>
                      Placer un poids sur chaque scale sélectionné puis préciser le Poids
                  </span>
                  <InputVue class="w-[200px] mt-2"
                  v-model="calibratingWeight" 
                  :height="'medium'" 
                  :label="'Poids en gramme'" 
                  :placeholder="'Poids en gramme'" 
                  :state="stateCalibratingWeight" 
                  :message="helperCalibratingWeight"/>
              </div>
          </template>
      </ModalVue>
      <ModalVue
      v-show="tareScales"
      :title="'Tare des Scales sélectionnés'"
      :confirmButtonLabel="'TARE ('+board.countSelected()+')'"
      @closeModal="tareScales = false"
      @confirm="tareScalesSelected()"
      >
          <template v-slot:body>
              <span>
                  Assurez-vous qu'il n'y ait plus aucun produit sur les scales sélectionnés avant de lancer la tare
              </span>
          </template>
      </ModalVue>
      <ModalVue
      v-show="associateProducts"
      :title="'Associer un Produit aux Scales sélectionnés'"
      :confirmButtonLabel="'AJOUTER ('+board.countSelected()+')'"
      @closeModal="associateProducts = false"
      @confirm="associateProductSelected()"
      >
          <template v-slot:body>
              <div class="flex flex-col flex-wrap justify-around items-center p-4 w-full">
                  <div class="p-2 mt-6 flex flex-row justify-between w-full"
                  :class="productMessage ? 'items-center' : 'items-end'">
                      <InputVue class="w-full"
                      :label="'Entrer ou Scanner un Code Barre'"
                      :placeholder="'Code Barre'"
                      v-model="barCode"
                      :height="'large'"
                      :state="productState"
                      :message="productMessage"
                      />
                      <ButtonVue class="ml-2"
                      :filling="'filled'"
                      :label="null"
                      :height="'large'"
                      :icon="'MagnifyingGlassIcon'"
                      :state="'neutral'"
                      @click="getProduct(barCode)"
                      />
                  </div>
                  <AutocompleteVue class="p-2 w-full"
                  v-model="brandAndRef" 
                  :height="'large'" 
                  :label="'Rechercher un produit'" 
                  :placeholder="'Marque Référence'"
                  :objects="products" 
                  @update:modelValue="getProducts()"
                  @setValue="e => setProduct(e)"/>
                  <ButtonVue class="w-[200px] mt-2"
                  :filling="'filled'"
                  :label="'VIDER L\'ÉTAGERE'"
                  :height="'medium'"
                  :state="board.countSelected() ? 'danger' : 'disabled'"
                  @click="board.emptySelected()"
                  />
              </div>
          </template>
      </ModalVue>
      </div>
      <div v-else class="w-full h-full flex flex-col p-10">
          <div class="flex flex-row justify-center md:justify-between flex-wrap">
              <div class="flex flex-col min-w-[300px] lg:mb-0 mb-2 w-full">
                    <div class="flex flex-row justify-between w-full items-center my-2">
                        <span class="text-title-3"> {{ board.serialNumber }} </span>
                        <span class="text-title-2" v-if="board.aisle && board.position && board.storey">Allée : {{ board.aisle }} - Rang : {{ board.position }} - Étage : {{ board.storey }}</span>
                        <div class="flex flex-row justify-start items-center">
                            <span>{{board.connected ? 'connecté' : `connecté le ${new Date(board.lastConnectedAt).toLocaleDateString()} à ${new Date(board.lastConnectedAt).toLocaleTimeString()}`}}</span>
                            <div class="w-[15px] h-[15px] rounded-[15px] ml-4 mr-2" :class="board.connected ? 'bg-success-600':'bg-danger-600'"></div>
                        </div>
                    </div>
                  <div class="flex items-center justify-between my-2 w-full bg-gray-200 p-4 rounded-lg shadow-lg mb-10"
                  :class="isMobile ? 'flex-col-reverse' : 'flex-row'">
                        <ButtonVue class="mr-4 w-full"
                        :filling="'filled'"
                        :label="'MODE : '+board.mode.toUpperCase()"
                        :height="'medium'"
                        :state="'default'"
                        :callbackState="callbackStates['mode']"
                        @click="board.toggleMode()"
                        />
                        <ButtonVue class="mx-4 w-full"
                        :filling="'filled'"
                        :label="'EMPLACEMENT'"
                        :height="'medium'"
                        :state="'default'"
                        @click="associateLocation = true"
                        />
                        <ButtonVue class="mx-4 w-full"
                        v-if="board.mode == 'settings'"
                        :filling="'filled'"
                        :label="'AUTO DISCOVERY'"
                        :height="'medium'"
                        :callbackState="callbackStates['autodiscovery']"
                        :state="board.connected ? 'default' : 'disabled'"
                        @click="board.autoDiscoverScales()"
                        />
                        <ButtonVue class="mx-4 w-full"
                        v-if="board.mode == 'settings'"
                        :filling="'filled'"
                        :label="'TARE ('+ board.countSelected()+')'"
                        :height="'medium'"
                        :callbackState="callbackStates['tare']"
                        :state="board.countSelected() && board.connected ? 'default' : 'disabled'"
                        @click="board.countSelected() ? tareScales = true : null"
                        />
                        <ButtonVue class="mx-4 w-full"
                        v-if="board.mode == 'settings'"
                        :filling="'filled'"
                        :label="'CALIBRER ('+ board.countSelected()+')'"
                        :height="'medium'"
                        :callbackState="callbackStates['calibrate']"
                        :state="board.countSelected() && board.connected ? 'default' : 'disabled'"
                        @click="board.countSelected() ? calibrateScales = true : null"
                        />
                        <ButtonVue class="mx-4 w-full"
                        :filling="'filled'"
                        :label="'ASSOCIER PRODUIT ('+board.countSelected()+')'"
                        :height="'medium'"
                        :state="board.countSelected() ? 'default' : 'disabled'"
                        @click="board.countSelected() ? associateProducts = true : null"
                        />
                        <ButtonVue class="mx-4 w-full"
                        :filling="'filled'"
                        :label="board.countSelected() ? 'UNSELECT ALL' : 'SELECT ALL'"
                        :height="'medium'"
                        :state="'default'"
                        @click="board.toggleAll()"
                        />
                        <ButtonVue class="ml-4 w-full"
                        :filling="'filled'"
                        :label="'SUPPRIMER'"
                        :height="'medium'"
                        :state="'danger'"
                        @click="deleteboard()"
                        />
                  </div>
              </div>
             
          </div>
          <div class="flex flex-row overflow-y-hidden overflow-x-auto pr-5 w-full justify-between h-full min-h-[40vh]">
                <div class="flex flex-col min-w-[150px] w-full mx-1"
                v-for="section in board.scales" 
                :key="section.id"
                :class="'w-['+(section.scaleOrder.length*12)+'%]'"
                @click="board.selected[section.id] = !board.selected[section.id]"
                >
                    <div class="w-full h-full flex flex-col p-2 mb-2 border-b-4 border-b-gray-800 relative min-h-[115px]" :class="board.selected[section.id] ? 'bg-primary-100' : null">
                        <div v-if="togglePictures">
                            <div v-for="product in section.products" :key="product.id" class="w-full h-full flex flex-col justify-end items-end">
                                <img :src="product.imageUrl" alt="" class="w-50%">
                            </div>
                        </div>
                        <div class="absolute -translate-y-1/4 bottom-0 left-2 flex flex-col items-center justify-center">
                            <div class="rounded-[100px] shadow-xl w-[4rem] h-[1.5rem] text-white flex flex-col items-center justify-center mb-2" :class="section.productCount && section.productCount > 0 ? 'bg-primary-800' : 'bg-danger-800'">
                                {{ section.productCount ? section.productCount : 0 }}
                            </div>
                            <div v-if="toggleWeights" class="rounded-[100px] shadow-xl w-[4rem] h-[1.5rem] text-black flex flex-col items-center justify-center bg-gray-300">
                                {{ section.weight ? section.weight : 0 }}
                            </div>
                        </div>
                    </div>
                    <div class="pt-5">
                        <div v-if="details && toggleReferences" class="w-full flex flex-col bg-gray-50 min-h-[115px] justify-start">
                            <VueQrcode :value="section.id" :color="{ dark: '#000000ff', light: '#ffffffff' }" :type="'image/png'"/>
                            <div v-for="product in section.products" :key="product.id" class="flex flex-col">
                                <span class="font-bold text-2xl"> {{ product.brand }}</span>
                                <span class="text-l">{{ product.reference }}</span>
                                <span class="text-sm">{{ product.barCode }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col m-2 h-full items-center justify-between min-h-[150px] pt-6">
                    <div @click="togglePictures = !togglePictures">
                        <component
                        :is="togglePictures ? heroIcons['ChevronUpIcon'] : heroIcons['ChevronDownIcon']"
                        class="pointer-events-none stroke-1 stroke-neutral-600 w-6 h-6"/>
                    </div>
                    <div @click="toggleWeights = !toggleWeights">
                        <component
                        :is="toggleWeights ? heroIcons['ChevronUpIcon'] : heroIcons['ChevronDownIcon']"
                        class="pointer-events-none stroke-1 stroke-neutral-600 w-6 h-6"/>
                    </div>
                    <div @click="toggleReferences = !toggleReferences">
                        <component
                        :is="toggleReferences ? heroIcons['ChevronUpIcon'] : heroIcons['ChevronDownIcon']"
                        class="pointer-events-none stroke-1 stroke-neutral-600 w-6 h-6"/>
                    </div>
                </div>
          </div>
      <ModalVue
      v-show="associateLocation"
      :title="'Associer un Emplacement au Linéaire'"
      :confirmButtonLabel="'CONFIRMER'"
      @closeModal="associateLocation = false"
      @confirm="changeLocation()"
      >
          <template v-slot:body>
              <div class="flex flex-col flex-wrap justify-around items-center p-4">
                  <InputVue class="w-25% min-w-[200px] mb-5"
                  v-model="board.aisle" 
                  :height="'large'" 
                  :label="'Allée'" 
                  :placeholder="'Numéro de l\'allée'" 
                  :state="stateAisle" 
                  :message="helperAisle"/>
                  <InputVue class="w-25% min-w-[200px] mb-5"
                  v-model="board.position" 
                  :height="'large'"
                  :label="'Rang'" 
                  :placeholder="'Numéro de rang'" 
                  :state="statePosition" 
                  :message="helperPosition"/>
                  <InputVue class="w-25% min-w-[200px]"
                  v-model="board.storey" 
                  :height="'large'" 
                  :label="'Étage'" 
                  :placeholder="'Numéro d\'étage'" 
                  :state="stateStorey" 
                  :message="helperStorey"/>
              </div>
          </template>
      </ModalVue>
      <ModalVue
      v-show="calibrateScales"
      :title="'Calibrer les Scales sélectionnés'"
      :confirmButtonLabel="'CALIBRER ('+board.countSelected()+')'"
      @closeModal="calibrateScales = false"
      @confirm="calibrateScalesSelected(calibratingWeight)"
      >
          <template v-slot:body>
              <div class="flex flex-col justify-center items-center p-4">
                  <span>
                      Avant la calibration, vous pouver effectuer une tare...
                  </span>
                  <span>
                      Pour se faire, assurez-vous qu'il n'y ait plus aucun produit sur les scales sélectionnés
                  </span>
                  <span class="flex flex-row w-85% items-center justify-center my-2">
                      Puis cliquez sur 
                  </span>
                  <ButtonVue class="w-[200px] mt-2"
                      :filling="'filled'"
                      :label="'TARE ('+ board.countSelected()+')'"
                      :height="'medium'"
                      :state="board.countSelected() ? 'default' : 'disabled'"
                      @click="tareScalesSelected()"
                      />
                  <span class="text-xl font-bold mt-2">
                      Sinon
                  </span>
                  <span>
                      Placer un poids sur chaque scale sélectionné puis préciser le Poids
                  </span>
                  <InputVue class="w-[200px] mt-2"
                  v-model="calibratingWeight" 
                  :height="'medium'" 
                  :label="'Poids en gramme'" 
                  :placeholder="'Poids en gramme'" 
                  :state="stateCalibratingWeight" 
                  :message="helperCalibratingWeight"/>
              </div>
          </template>
      </ModalVue>
      <ModalVue
      v-show="tareScales"
      :title="'Tare des Scales sélectionnés'"
      :confirmButtonLabel="'TARE ('+board.countSelected()+')'"
      @closeModal="tareScales = false"
      @confirm="tareScalesSelected()"
      >
          <template v-slot:body>
              <span>
                  Assurez-vous qu'il n'y ait plus aucun produit sur les scales sélectionnés avant de lancer la tare
              </span>
          </template>
      </ModalVue>
      <ModalVue
      v-show="associateProducts"
      :title="'Associer un Produit aux Scales sélectionnés'"
      :confirmButtonLabel="'AJOUTER ('+board.countSelected()+')'"
      @closeModal="associateProducts = false"
      @confirm="associateProductSelected()"
      >
          <template v-slot:body>
              <div class="flex flex-col flex-wrap justify-around items-center p-4 w-full">
                  <div class="p-2 mt-6 flex flex-row justify-between w-full"
                  :class="productMessage ? 'items-center' : 'items-end'">
                      <InputVue class="w-full"
                      :label="'Entrer ou Scanner un Code Barre'"
                      :placeholder="'Code Barre'"
                      v-model="barCode"
                      :height="'large'"
                      :state="productState"
                      :message="productMessage"
                      />
                      <ButtonVue class="ml-2"
                      :filling="'filled'"
                      :label="null"
                      :height="'large'"
                      :icon="'MagnifyingGlassIcon'"
                      :state="'neutral'"
                      @click="getProduct(barCode)"
                      />
                  </div>
                  <div id="data-capture-view" class="mb-6 w-full h-[50vh]"></div>
                  <AutocompleteVue class="p-2 w-full"
                  v-model="brandAndRef" 
                  :height="'large'" 
                  :label="'Rechercher un produit'" 
                  :placeholder="'Marque Référence'"
                  :objects="products" 
                  @update:modelValue="getProducts()"
                  @setValue="e => setProduct(e)"/>
                  <ButtonVue class="w-[200px] mt-2"
                  :filling="'filled'"
                  :label="'VIDER L\'ÉTAGERE'"
                  :height="'medium'"
                  :state="board.countSelected() ? 'danger' : 'disabled'"
                  @click="board.emptySelected()"
                  />
              </div>
          </template>
      </ModalVue>
      </div>
  </div>
</template>

<script>
import axios from 'axios'
import Board from '@/utils/entities/Board'
import Product from '@/utils/entities/Product'
import LoadingVue from '../components/elements/Loading.vue'
import VueQrcode from 'vue-qrcode'
import InputVue from '../components/elements/Input.vue'
import ButtonVue from '../components/elements/Button.vue'
import ModalVue from '../components/containers/Modal.vue'
import AutocompleteVue from '../components/elements/Autocomplete.vue'
import WebsocketService from '@/utils/WebsocketService'

import * as heroIcons from '@heroicons/vue/24/outline'
import * as SDCCore from 'scandit-web-datacapture-core';
import * as SDCBarcode from 'scandit-web-datacapture-barcode';

export default ({
  name: 'ShelfView',
  components: {
      LoadingVue,
      VueQrcode,
      InputVue,
      ButtonVue,
      ModalVue,
      AutocompleteVue
  },
  props:{
      details:{
          type: Boolean,
          default: true
      },
      inputProducts:{
          type: Object,
          default: null
      },
  },
  data() {
      return {
            heroIcons: heroIcons,
            loaded: false,
            board: {},
            state: undefined,

            associateLocation: false,
            tareScales: false,
            calibrateScales: false,
            associateProducts: false,

            calibratingWeight: '500',
            stateCalibratingWeight: "default",
            helperCalibratingWeight: "",

            stateAisle: "default",
            statePosition: "default",
            stateStorey: "default",
            helperAisle: "",
            helperPosition: "",
            helperStorey: "",

            barCode: null,
            product : null,
            productState: 'default',
            productMessage: null,

            brandAndRef: "",
            products : [],

            updateClicked: false,
            toggleWeights: false,
            togglePictures: true,
            toggleReferences: true,

            callbackStates: []

      }
  },    
  computed: {
      isMobile() {
            return this.$device.isMobile;
        },
        isPortrait() {
            return this.$device.isPortrait;
        },
    },
  async mounted(){
        WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);
        this.board = new Board({ serialNumber: this.$route.params.id})
        await this.board.retrieveEntity().then(async () => {
            await this.waitForWebSocketConnection().then(() => {
                this.board.initializeWebSocketListener();
                WebsocketService.sendMessage({
                    command: 'ping',
                    resource: `/api/boards/${this.board.serialNumber}`,
                });
            });
        })

        WebsocketService.addListener((type, data) => {
            if (type === 'message') {
                this.handleWebSocketMessage(data);
            }
        });

        this.loaded = true
  },
  unmounted(){
    this.board.unsubscribeWebSocket()
  },
  methods:{
        async waitForWebSocketConnection() {
            return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (WebsocketService.isConnected) {
                clearInterval(interval);
                resolve();
                }
            }, 1000); // Check every 1 second
            });
        },
      handleWebSocketMessage(data){
          if(data.resource === "/api/boards/"+this.board.serialNumber){
                switch (data.command){
                    case "acknowledgment": 
                        this.board.connected = true
                        switch (data.value){
                            case "tare":
                                this.callbackStates['tare'] = 'loading'
                                break;
                            case "calibrate_scale":
                                this.callbackStates['calibrate'] = 'loading'
                                break;
                            case "mode":
                                this.callbackStates['mode'] = 'loading'
                                break;
                            case "autodiscovery":
                                this.callbackStates['autodiscovery'] = 'loading'
                                break;
                        }
                        if(data.value === 'update'){
                            alert('La demande de mise à jour a bien été prise en compte')
                            window.location.reload()
                        }
                        break;
                    case "tare_response":
                        this.callbackStates['tare'] = 'success'
                        break;
                    case "calibrate_scale_response": 
                        this.callbackStates['calibrate'] = 'success'
                        break;
                    case "autodiscovery_response": 
                        this.callbackStates['autodiscovery'] = 'success'
                        break;
                    case "mode_response": 
                        this.callbackStates['mode'] = 'success'
                        break;
                    case "delete":
                        this.$router.replace('/boards')
                        break;
                    default:
                        break;
              }
          } 
      },
      productsForSelect(){
          return this.products.map(element => {
              return { 
                  value: element.barCode, 
                  name: element.brand + ' ' + element.reference
              }
          })
      },
      async getProduct(barcode){
          await axios
          .get(process.env.VUE_APP_API_BASE_PATH+'/products/'+ barcode,
          {
              withCredentials: true
          })
          .then(response => {
              if(response.status == 200){
                  this.product = new Product(response.data)
                  this.productMessage = this.product.brand+', '+this.product.reference
                  this.productState = "success"
              }
          })
          .catch(() =>{
              this.product = null
              this.productMessage = "Code Barre non reconnu"
              this.productState = "danger"
          });
      
      },

      async getProducts(){
          await axios
          .get(process.env.VUE_APP_API_BASE_PATH+'/products?simplesearch='+this.brandAndRef,
          {
              withCredentials: true
          })
          .then(response => {
              this.products = response.data['hydra:member']
              this.products.forEach((element,index) => {
                  this.products[index].description = element.brand+', '+element.reference
              })

          })
      },

      setProduct(event){
          this.brandAndRef = event.description
          this.product = new Product(event)
          this.products = []
      },


      async deleteboard(){
          if (confirm("Êtes-vous sûr de vouloir supprimer le linéaire ? Cette action est irréversible")) {
              await axios
              .delete(process.env.VUE_APP_API_BASE_PATH+'/boards/'+this.$route.params.id,
              {
                  withCredentials:true,
              })
              .then(response => {
                  if(response.status == 204){
                      this.$router.replace('/boards')
                  }
              })
          }
      },
      ping(){
          this.updateClicked = false
      },
      calibrateScalesSelected(weight){
            var values = []
            Object.keys(this.board.selected).forEach(element => {
                const scale = this.board.scales.find(key => element === key.id);
                if (scale) {
                    var scaleNumber = scale.scaleOrder;
                    if (this.board.selected[element]) {
                        values.push(scaleNumber);
                    }
                }
            });
            WebsocketService.sendMessage({
                resource: '/api/boards/'+this.board.serialNumber,
                command: 'calibrate_scale',
                value: values,
                weight: parseInt(weight)
            });
            this.calibrateScales = false
            this.board.toggleAll()
      },
      tareScalesSelected(){
          var values = []
          Object.keys(this.board.selected).forEach(element => {
                const scale = this.board.scales.find(key => element === key.id);
                if (scale) {
                    var scaleNumber = scale.scaleOrder;
                    if (this.board.selected[element]) {
                        values.push(scaleNumber);
                    }
                }
            });
          WebsocketService.sendMessage({
                resource: '/api/boards/'+this.board.serialNumber,
                command: 'tare',
                value: values
          });
          this.tareScales = false
          this.board.toggleAll()
      },
      update(){
          this.updateClicked = true
          this.board.update()
      },
      async associateProductSelected(){
          if(this.product){
              let json = {
                   scales: []
              }
              Object.keys(this.board.selected).forEach(element => {
                  if(this.board.selected[element]){
                      json.scales.push({
                          id : element
                      })
                  }
              })
              await this.product.associateProduct(json).then(async () => {
                await this.board.retrieveEntity()
              })
              this.associateProducts = false
          }
      },
      changeLocation(){
          var temoin = [false,false,false]
          if(!this.board.aisle){
              temoin[0] = true
              this.stateAisle = "danger" 
              this.helperAisle = "Doit être définit"
          } else {
              this.stateAisle = "default" 
              this.helperAisle = ""
          }
          if(!this.board.position){
              temoin[1] = true
              this.statePosition = "danger" 
              this.helperPosition = "Doit être définit"
          } else {
              this.statePosition = "default" 
              this.helperPosition = ""
          }
          if(!this.board.storey){
              temoin[2] = true
              this.stateStorey = "danger" 
              this.helperStorey = "Doit être définit"
          } else {
              this.stateStorey = "default" 
              this.helperStorey = ""
          }
          for (var i = 0; i < temoin.length; i++){
              if (temoin[i]){
                  return
              }
          }
          this.board.changeLocation()
          this.associateLocation = false
      },
  },
  watch:{
    async product() {
        this.associateProductSelected()
    },
                
    async associateProducts() {
      if(this.associateProducts){
        await SDCCore.configure({
          licenseKey: process.env.VUE_APP_SCANDIT_LICENSE_KEY,
          libraryLocation: 'https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/',
          scaleLoaders: [SDCBarcode.barcodeCaptureLoader()],
        });
        const context = await SDCCore.DataCaptureContext.create();
        const view = await SDCCore.DataCaptureView.forContext(context);
        const element = document.getElementById('data-capture-view');
        view.connectToElement(element);

        const settings = new SDCBarcode.BarcodeCaptureSettings();
        settings.enableSymbologies([
          SDCBarcode.Symbology.Code128,
          SDCBarcode.Symbology.Code39,
          SDCBarcode.Symbology.QR,
          SDCBarcode.Symbology.EAN8,
          SDCBarcode.Symbology.UPCE,
          SDCBarcode.Symbology.EAN13UPCA,
        ]);

        const barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(context, settings);

        const camera = SDCCore.Camera.default;
        await camera.applySettings(SDCBarcode.BarcodeCapture.recommendedCameraSettings);
        await context.setFrameSource(camera);
        await camera.switchToDesiredState(SDCCore.FrameSourceState.On);

        barcodeCapture.addListener({
          didScan: async (barcodeCapture, session) => {
            const recognizedBarcodes = session.newlyRecognizedBarcodes;
            if (recognizedBarcodes.length > 0) {
              this.barCode = recognizedBarcodes[0]._data;
              console.log(this.barCode);
              barcodeCapture = null;
              this.sheetProduct = false;

              await camera.switchToDesiredState(SDCCore.FrameSourceState.Off);
              await this.getProduct(this.barCode);
            }
          },
        });
      }
    }
  },
})

</script>